import Loading from "./loading";
import PopupDelete from "./popupDelete";
import { GlobalApi } from "../global";
import { useNavigation } from "../Redux/useNavigation";
import React, { useState } from "react";

const TableVault = ({ data, setData }) => {
  const [loading, setLoading] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [deleteFolderId, setDeleteFolderId] = useState("");
  const { navigateTo } = useNavigation();
  const [popup, setPopup] = useState(false);
  const handleOpenDir = (id) => {
    setData((prevFolders) =>
      prevFolders.map((folder) =>
        folder.FolderID === id
          ? { ...folder, Checkboxe: !folder.Checkboxe }
          : folder,
      ),
    );
  };

  const handleDelete = (EnvFileID, FolderID) => {
    setPopup(true);
    setDeleteId(EnvFileID);
    setDeleteFolderId(FolderID);
  };

  const handlApprouver = async () => {
    setPopup(false);
    setLoading(true);
    try {
      const response = await fetch(
        `${GlobalApi()}/api/vault/delete/${deleteId}/${deleteFolderId}`,
        {
          method: "DELETE",
          credentials: "include",
        },
      );
      const data_rec = await response.json();
      if (data_rec.status === "ok") {
        setData((prevState) => {
          return prevState.map((folder) => {
            return {
              ...folder,
              EnvFiles: folder.EnvFiles.filter(
                (envFile) => envFile.EnvFileID !== deleteId,
              ),
            };
          });
        });
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };
  return (
    <div className="overflow-x-auto">
      {popup && (
        <PopupDelete
          setPopup={setPopup}
          handleDelete={handlApprouver}
          id={deleteId}
          text="Are you sure you want delete env"
        />
      )}
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-primary_token/60 uppercase bg-base">
          <tr>
            <th scope="col" className="px-3 w-2 py-3">
              <input type="checkbox" className="bg-base" />
            </th>
            <th scope="col" className="px-3 w-full py-3">
              Name
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <React.Fragment key={item.FolderID}>
              <tr className="bg-base/40">
                <th
                  scope="row"
                  className="px-3 w-56 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  <input type="checkbox" className="bg-base" />
                </th>
                <th
                  scope="row"
                  className="px-3 w-full py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  <div className="flex items-center gap-2">
                    {item.Checkboxe ? (
                      <button
                        className="hover:bg-gray-600 rounded-sm"
                        onClick={() => handleOpenDir(item.FolderID)}
                        type="button"
                      >
                        <svg
                          className="svg-icon text-primary_token cursor-pointer rotate-90"
                          viewBox="0 0 20 20"
                          stroke="currentColor"
                          width="18"
                          height="18"
                        >
                          <path
                            fill="none"
                            d="M11.611,10.049l-4.76-4.873c-0.303-0.31-0.297-0.804,0.012-1.105c0.309-0.304,0.803-0.293,1.105,0.012l5.306,5.433c0.304,0.31,0.296,0.805-0.012,1.105L7.83,15.928c-0.152,0.148-0.35,0.223-0.547,0.223c-0.203,0-0.406-0.08-0.559-0.236c-0.303-0.309-0.295-0.803,0.012-1.104L11.611,10.049z"
                          ></path>
                        </svg>
                      </button>
                    ) : (
                      <button
                        onClick={() => handleOpenDir(item.FolderID)}
                        className="hover:bg-gray-600 rounded-sm"
                        type="button"
                      >
                        <svg
                          className="svg-icon text-primary_token cursor-pointer"
                          viewBox="0 0 20 20"
                          stroke="currentColor"
                          width="18"
                          height="18"
                        >
                          <path
                            fill="none"
                            d="M11.611,10.049l-4.76-4.873c-0.303-0.31-0.297-0.804,0.012-1.105c0.309-0.304,0.803-0.293,1.105,0.012l5.306,5.433c0.304,0.31,0.296,0.805-0.012,1.105L7.83,15.928c-0.152,0.148-0.35,0.223-0.547,0.223c-0.203,0-0.406-0.08-0.559-0.236c-0.303-0.309-0.295-0.803,0.012-1.104L11.611,10.049z"
                          ></path>
                        </svg>
                      </button>
                    )}
                    {item.FolderName}
                  </div>
                </th>
              </tr>
              {item.Checkboxe &&
                item.EnvFiles &&
                item.EnvFiles.map((e) => (
                  <tr key={e.EnvFileID} className="bg-base/40">
                    <th
                      scope="row"
                      className="px-3 w-56 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      <input type="checkbox" className="bg-base" />
                    </th>
                    <th
                      scope="row"
                      className="flex item-center justify-between px-10 w-full py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white hover:cursor-pointer"
                    >
                      <button
                        onClick={() =>
                          navigateTo(
                            "/vaults/view/" + e.EnvFileID + "/" + item.FolderID,
                          )
                        }
                      >
                        {e.FileName}
                      </button>
                      <div className="flex items-center gap-2">
                        {loading && <Loading />}
                        {!loading && (
                          <>
                            <button
                              className="p-2 bg-primary hover:bg-primary/80 rounded-md"
                              onClick={() =>
                                navigateTo(
                                  `/vaults/edit/${e.EnvFileID}/${item.FolderID}`,
                                )
                              }
                            >
                              <svg
                                width="18"
                                height="18"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g
                                  fill="none"
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                >
                                  <path d="M7 7H6a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2-2v-1" />
                                  <path d="M20.385 6.585a2.1 2.1 0 0 0-2.97-2.97L9 12v3h3zM16 5l3 3" />
                                </g>
                              </svg>
                            </button>
                            <button
                              className="p-2 bg-primary hover:bg-primary/80 rounded-md"
                              onClick={() =>
                                handleDelete(e.EnvFileID, e.FolderID)
                              }
                            >
                              <svg
                                width="18"
                                height="18"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill="currentColor"
                                  d="M7 21q-.825 0-1.412-.587T5 19V6H4V4h5V3h6v1h5v2h-1v13q0 .825-.587 1.413T17 21zM17 6H7v13h10zM9 17h2V8H9zm4 0h2V8h-2zM7 6v13z"
                                />
                              </svg>
                            </button>
                          </>
                        )}
                      </div>
                    </th>
                  </tr>
                ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableVault;
