import React, { useRef, useState, useEffect, useCallback } from "react";
import TemplatePage from "../template/template";
import TableOfContents from "../../components/Docs/ContentSelector";
import {
  Console,
  Content,
  DocTitle,
  PromptInfo,
  Ref,
  SubTitle,
  Title,
} from "../../components/Docs/Title";
import Loading from "../../components/loading";
import { useLocation, useNavigate } from "react-router-dom";
import { GlobalApi } from "../../global";

const DynamicDocRenderer = () => {
  const [loading, setLoading] = useState(false);
  const contentRef = useRef(null);
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState(null);
  const [activeSub, setActiveSub] = useState(null);
  const titleRefs = useRef([]);
  const subRefs = useRef([]);
  const location = useLocation();
  const path = location.pathname;
  const docName = path.split("/")[2];
  const [jsonData, setJsonData] = useState(null);
  const [error, setError] = useState("");
  const [date, setDate] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError("");
        const response = await fetch(
          `${GlobalApi()}/api/docs/json/${docName}`,
          {
            method: "GET",
            credentials: "include",
          },
        );
        const data = await response.json();
        if (data.status === "error") {
          if (data.type === "jwt") {
            navigate("/login");
          }
          setError(data.error);
          setLoading(false);
          return;
        } else if (data.status === "ok") {
          setJsonData(data.doc);
          setDate(data.doc.updatedAt);
        }
      } catch (e) {
        setError(e.message);
      }
      setLoading(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      requestAnimationFrame(() => {
        const isInViewport = (element) => {
          const rect = element.getBoundingClientRect();
          return rect.top >= 0 && rect.bottom <= window.innerHeight;
        };

        const visibleTitle = titleRefs.current.find((ref) => isInViewport(ref));
        const visibleSubtitle = subRefs.current.find((ref) =>
          isInViewport(ref),
        );

        if (visibleTitle) {
          setActiveSection(visibleTitle.textContent);
        }
        if (visibleSubtitle) {
          setActiveSub(visibleSubtitle.textContent);
        }
      });
    };

    const contentEl = contentRef.current;
    if (contentEl) {
      contentEl.addEventListener("scroll", handleScroll);
      return () => {
        contentEl.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const renderRef = useCallback(
    (content) => {
      const parts = content.split(/(<Ref.*?<\/Ref>)/);
      return parts.map((part, index) => {
        if (part.startsWith("<Ref")) {
          const hrefMatch = part.match(/href="(.*?)"/);
          const textMatch = part.match(/>(.+?)</);
          if (hrefMatch && textMatch) {
            return (
              <Ref key={index} href={hrefMatch[1]}>
                {textMatch[1]}
              </Ref>
            );
          }
        }
        return part;
      });
    },
    // eslint-disable-next-line
    [date],
  );

  const renderComponent = useCallback(
    (component, index, date) => {
      switch (component.type) {
        case "DocTitle":
          return (
            <DocTitle key={index} post={component.post || ""} update={date}>
              {renderRef(component.content)}
            </DocTitle>
          );
        case "Content":
          return <Content key={index}>{renderRef(component.content)}</Content>;
        case "Title":
          return (
            <Title
              key={index}
              ref_props={titleRefs}
              count={titleRefs.current.length}
            >
              {renderRef(component.content)}
            </Title>
          );
        case "SubTitle":
          return (
            <SubTitle
              key={index}
              ref_props={subRefs}
              count={subRefs.current.length}
            >
              {renderRef(component.content)}
            </SubTitle>
          );
        case "PromptInfo":
          return (
            <PromptInfo key={index}>{renderRef(component.content)}</PromptInfo>
          );
        case "Console":
          return (
            <Console key={index} type={component.consoleType || "shell"}>
              {component.content}
            </Console>
          );
        default:
          return <div key={index}>{renderRef(component.content)}</div>;
      }
    },
    [renderRef],
  );

  return (
    <TemplatePage loading={loading} setLoading={setLoading}>
      {loading && <Loading />}
      {error && <div className="text-red-500">{error}</div>}
      {jsonData && (
        <div className="flex flex-col items-center  overflow-y-scroll scrollbar">
          <div className="flex flex-1 pb-10 pt-10 h-full w-full max-w-7xl">
            <div
              ref={contentRef}
              id="content"
              className="flex flex-col w-full h-full scrollbar overflow-y-scroll gap-4 pb-16"
            >
              {jsonData.components.map((component, index) =>
                renderComponent(component, index, date),
              )}
            </div>
            <TableOfContents
              activeSection={activeSection}
              activeSub={activeSub}
            />
          </div>
        </div>
      )}
    </TemplatePage>
  );
};

export default DynamicDocRenderer;
