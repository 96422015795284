import { useEffect, useState } from "react";
import { GlobalApi } from "../global";
import { useLocation } from "react-router-dom";
import { useNavigation } from "../Redux/useNavigation";

const Header = ({ isMenu }) => {
  const { navigateTo } = useNavigation();
  const [dropdown, setDropdown] = useState(false);
  const location = useLocation();
  const [active, setActive] = useState(false);
  const path = location.pathname;

  useEffect(() => {
    if (path === "/login" || path === "/register" || path === "/reset") {
      setActive(false);
    } else {
      setActive(true);
    }
  }, [path]);

  function toggleDropdown() {
    setDropdown(!dropdown);
  }

  const logout = async () => {
    setDropdown(false);
    try {
      await fetch(`${GlobalApi()}/api/logout`, {
        method: "POST",
        credentials: "include",
      });
    } catch (e) {
      console.error(e);
    }
    navigateTo("/login");
  };

  return (
    <div className="relative navbar bg-base border-b border-baseLight top-0 px-4 z-10 flex items-center w-screen min-h-16 justify-between">
      <div className="navbar-start flex items-center justify-between w-full">
        <button onClick={() => navigateTo("/")}>
          <div className="flex items-center gap-2">
            <div className="relative w-9 h-9 rounded-full">
              <div className="absolute rounded-full inset-0 w-full h-full bg-gradient-to-r from-pink-400 via-red-400 to-orange-400 "></div>
              <img
                className="absolute inset-0 w-full h-full object-cover"
                src="/lock.svg"
                alt="lock"
              />
              <span>
                <img
                  className={`absolute ${active ? "rotate-90" : ""} duration-100 ease-in-out object-cover h-1/3 w-auto left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2`}
                  src="/keyhole.png"
                  alt="keyhole"
                />
              </span>
            </div>
            <div className="font-brand text-3xl bg-gradient-to-r from-pink-400 via-red-400 to-orange-400 inline-block text-transparent bg-clip-text">
              Vault
            </div>
          </div>
        </button>
        {isMenu && (
          <button
            id="dropdownDefaultButton"
            onClick={toggleDropdown}
            className="text-primary_token hover:bg-chat font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
            type="button"
          >
            My account
            <svg
              className="w-2.5 h-2.5 ms-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 4 4 4-4"
              ></path>
            </svg>
          </button>
        )}
        {dropdown && (
          <div
            id="dropdown"
            className="absolute top-full right-4 z-10 bg-base divide-y rounded-lg shadow w-36 shadow-white/45"
          >
            <ul
              className="flex flex-col items-start w-full py-2 text-sm text-gray-700 dark:text-gray-200"
              aria-labelledby="dropdownDefaultButton"
            >
              <li className="w-full">
                <a
                  href="/profile"
                  className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  My profile
                </a>
              </li>
              <li className="w-full">
                <button
                  id="logout"
                  className="w-full text-left block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  onClick={logout}
                >
                  Log out
                </button>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
