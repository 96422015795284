import { useEffect, useState } from "react";
import DocumentList from "../../components/Docs/docList";
import TemplatePage from "../template/template";
import { GlobalApi } from "../../global";
import { useNavigate } from "react-router-dom";

const DocumentListPage = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [documents, setDocuments] = useState([]);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(6);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoaded(true);
      setError("");
      try {
        const response = await fetch(
          `${GlobalApi()}/api/docs/list?page=${currentPage}&limit=${itemsPerPage}&search=${searchTerm}`,
          {
            method: "GET",
            credentials: "include",
          },
        );
        const data = await response.json();
        if (data.status === "error") {
          if (data.type === "jwt") {
            navigate("/login");
          }
          setError(data.error);
          return;
        } else if (data.status === "ok") {
          setDocuments(data.docs);
          setTotalPages(data.totalPages);
        }
      } catch (e) {
        setError(e.message);
      }
      setIsLoaded(false);
    };
    fetchData();
  }, [currentPage, itemsPerPage, navigate, searchTerm]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <TemplatePage loading={loading} setLoading={setLoading}>
      <div className="mt-8 flex flex-col gap-4 overflow-y-scroll scrollbar">
        {error && <p className="text-red-500">{error}</p>}
        {!error && (
          <DocumentList
            currentPage={currentPage}
            onPageChange={handlePageChange}
            setCurrentPage={setCurrentPage}
            totalPages={totalPages}
            documents={documents}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            isLoading={isLoaded}
          />
        )}
      </div>
    </TemplatePage>
  );
};

export default DocumentListPage;
