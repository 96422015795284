import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import TemplatePage from "../template/template";
import { GlobalApi } from "../../global";
import BreadCrumbPath from "../../components/breadcrumbPath";
import CloudStorage from "../../components/cloudStorage";
import { useNavigation } from "../../Redux/useNavigation";

const Cloud = () => {
  const { navigateTo } = useNavigation();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [name, setName] = useState("");
  const searchParams = new URLSearchParams(location.search);
  const [NewFolder, setNewFolder] = useState(false);
  const [content, setContent] = useState([]);
  const [reload, setReload] = useState(0);

  useEffect(() => {
    const getContentFolder = async () => {
      setLoading(true);
      setError("");
      try {
        const formData = new FormData();
        formData.append("path", searchParams.get("path") || "/home");
        const response = await fetch(
          `${GlobalApi()}/api/cloud/FolderContents`,
          {
            method: "POST",
            credentials: "include",
            body: formData,
          },
        );
        const data = await response.json();
        if (data.status === "error" && data.type === "jwt") {
          navigateTo("/login");
        }
        if (data.status === "error") {
          setError(data.error);
        } else if (data.status === "ok") {
          setContent(data);
        }
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    };

    getContentFolder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.get("path"), reload]);

  const triggerReload = () => {
    setReload((prev) => prev + 1);
  };

  const handleNewFolder = async () => {
    setLoading(true);
    setError("");
    setNewFolder(false);
    setName("");
    try {
      if (!name) {
        setError("All fields are required");
        setLoading(false);
        return;
      }
      const formData = new FormData();
      formData.append("name", name);
      formData.append("path", searchParams.get("path") || "/home");
      const response = await fetch(`${GlobalApi()}/api/cloud/new_folder`, {
        method: "POST",
        credentials: "include",
        body: formData,
      });
      const data = await response.json();
      if (data.status === "error" && data.type === "jwt") {
        navigateTo("/login");
      }
      if (data.status === "error") {
        setError(data.error);
      } else if (data.status === "ok") {
        triggerReload();
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  return (
    <TemplatePage loading={loading} setLoading={setLoading}>
      <BreadCrumbPath />
      <div className="scrollbar flex-grow home_selection_models w-full max-h-[calc(100vh-160px)] py-4 flex flex-col gap-4 overflow-y-scroll">
        <div className="flex items-start justify-between w-full">
          <div className="flex flex-col gap-2 mt-2">
            <h1 className="text-primary_token text-2xl">Cloud</h1>
            <p className="text-gray-600">Storage file</p>
          </div>
          <div className="pr-2 flex gap-2">
            <button
              onClick={() => setNewFolder(true)}
              className="px-4 py-2 bg-primary/80 hover:bg-primary/95 rounded-md mt-10"
            >
              New folder
            </button>
            <button
              onClick={() =>
                navigateTo(
                  `/Cloud/Uploads?path=${searchParams.get("path") || "/"}`,
                )
              }
              className="px-4 py-2 bg-primary/80 hover:bg-primary/95 rounded-md mt-10"
            >
              Upload file
            </button>
          </div>
        </div>
        {error && <p className="text-red-600">{error}</p>}
        {NewFolder && (
          <form onSubmit={handleNewFolder}>
            <div className="w-full flex flex-col gap-4 mt-4">
              <input
                autoFocus
                autoCapitalize="none"
                autoCorrect="off"
                type="text"
                id="newFolder"
                name="newFolder"
                placeholder="New folder name"
                onChange={(e) => setName(e.target.value)}
                value={name}
                className="w-full bg-gray-[#121C22] text-gray-400 h-8 p-1 border rounded-md border-primary bg-chat focus:ring focus:outline-none"
              />
            </div>
          </form>
        )}
        <CloudStorage data={content} triggerReload={triggerReload} />
      </div>
    </TemplatePage>
  );
};

export default Cloud;
