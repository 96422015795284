import TemplatePage from "../template/template";
import { useEffect, useState } from "react";
import { GlobalApi } from "../../global";
import RoleDrop from "../../components/roleDrop";
import { useNavigation } from "../../Redux/useNavigation";
import { useParams } from "react-router-dom";

const Edit = () => {
  const { id } = useParams();
  const { navigateTo } = useNavigation();
  const [loading, setLoading] = useState(false);
  const [team, setTeam] = useState({});
  const [error, setError] = useState("");
  const [addActive, setAddActive] = useState(false);
  const [usersList, setUsers] = useState([]);
  const [dropdown, setDropdown] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [activeFilter, setActiveFilter] = useState(false);
  const [role, setRole] = useState("edit");

  const handleUser = (user) => {
    setSelectedUser(user);
    setDropdown(false);
  };

  const handleAddUser = async () => {
    setLoading(true);
    setDropdown(false);
    setAddActive(false);
    try {
      const formData = new FormData();
      formData.append("TeamID", id);
      formData.append("email", selectedUser.email);
      formData.append("role", role);
      const response = await fetch(`${GlobalApi()}/api/team/user/add`, {
        method: "POST",
        body: formData,
        credentials: "include",
      });
      const data = await response.json();
      if (data.status === "error") {
        if (data.type === "jwt") {
          navigateTo("/login");
        }
        setError(data.error);
      } else if (data.status === "ok") {
        setUsers(data.Users);
        if (data.Users.length > 0) {
          setSelectedUser(data.Users[0]);
        }
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  const handleAdd = async () => {
    setError("");
    setLoading(true);
    setAddActive(!addActive);
    try {
      setLoading(true);
      const response = await fetch(`${GlobalApi()}/api/user/list`, {
        method: "POST",
        credentials: "include",
      });
      const data = await response.json();
      if (data.status === "error") {
        if (data.type === "jwt") {
          navigateTo("/login");
        }
        setError(data.error);
      } else if (data.status === "ok" && data.Users) {
        setUsers(data.Users);
        setSelectedUser(data.Users[0]);
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  const getTeam = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${GlobalApi()}/api/teams/${id}`, {
        method: "POST",
        credentials: "include",
      });
      const data = await response.json();
      if (data.status === "error") {
        if (data.type === "jwt") {
          navigateTo("/login");
        }
        setError(data.error);
      } else if (data.status === "ok") {
        setTeam(data.team);
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    getTeam();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TemplatePage loading={loading} setLoading={setLoading}>
      {error && <p className="text-red-500 text-sm my-3">{error}</p>}
      {team && (
        <div className="flex flex-col gap-3 mt-4 text-primary_token">
          <h1 className="text-xl text-primary_token">{team.TeamName}</h1>
          <p className="text-sm text-gray-500">Manage members</p>

          {/* First table */}
          <div className="overflow-x-auto">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-primary_token/60 uppercase bg-base">
                <tr>
                  <th scope="col" className="px-3 w-2/4 py-3">
                    User
                  </th>
                  <th scope="col" className="px-3 w-1/4 py-3">
                    Role
                  </th>
                  <th scope="col" className="px-3 w-1/4 py-3">
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                {team.TeamMembers &&
                  team.TeamMembers.map((item) => (
                    <tr key={item.TeamID} className="bg-base/40">
                      <td className="px-3 py-3">{item.Email}</td>
                      <td className="px-3 py-3">{item.Role}</td>
                      <td className="px-3 py-3">
                        <button
                          type="button"
                          className="bg-red-500 p-1 rounded-sm"
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill="currentColor"
                              d="m9.4 16.5l2.6-2.6l2.6 2.6l1.4-1.4l-2.6-2.6L16 9.9l-1.4-1.4l-2.6 2.6l-2.6-2.6L8 9.9l2.6 2.6L8 15.1zM5 21V6H4V4h5V3h6v1h5v2h-1v15zm2-2h10V6H7zM7 6v13z"
                            />
                          </svg>
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          <div className="flex justify-start mt-10">
            <button
              type="button"
              onClick={() => handleAdd()}
              className="btn bg-primary p-2 rounded-md hover:bg-primary/80"
            >
              + Add member
            </button>
          </div>

          {/* Second table (Add member) */}
          {addActive && (
            <div className="flex flex-col justify-start mt-10 p-2">
              <h2 className="text-lg my-2">Add member</h2>
              <div className="overflow-x-auto">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <thead className="text-xs text-primary_token/60 uppercase bg-base">
                    <tr>
                      <th className="px-3 py-3">Member</th>
                      <th className="px-3 py-3">Role</th>
                      <th className="px-3 py-3">Add</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="bg-base/40">
                      <td className="px-3 py-3">
                        {activeFilter && selectedUser ? (
                          <div className="">
                            <input
                              className="w-full bg-gray-[#121C22] text-gray-400 p-1 border rounded-md border-primary bg-chat focus:ring focus:outline-none"
                              type="text"
                              placeholder={selectedUser.email}
                              onFocus={() => setDropdown(true)}
                              onBlur={() => setDropdown(false)}
                              name="filter"
                            />
                            {dropdown && (
                              <div className="absolute min-w-10 max-w-1/2 bg-base/80 z-50 max-h-36 overflow-y-auto p-1">
                                {usersList.map((user) => (
                                  <button
                                    key={user.UserID}
                                    type="button"
                                    onClick={() => handleUser(user)}
                                    className="p-1 hover:border-gray-400 border-gray-600 border-[1px] w-full overflow-hidden h-14"
                                  >
                                    {user.email}
                                  </button>
                                ))}
                              </div>
                            )}
                          </div>
                        ) : (
                          <button
                            type="button"
                            onClick={() => setActiveFilter(true)}
                            className="p-1 hover:border-gray-400 border-gray-600 border-[1px] w-full h-8 rounded-md overflow-hidden"
                          >
                            {selectedUser?.email || "Select user"}
                          </button>
                        )}
                      </td>
                      <td className="px-3 py-3">
                        <RoleDrop role={role} setRole={setRole} />
                      </td>
                      <td className="px-3 py-3">
                        <button
                          className="btn btn-primary rounded-md py-2 px-4"
                          type="button"
                          onClick={() => handleAddUser()}
                        >
                          Add
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      )}
    </TemplatePage>
  );
};

export default Edit;
