import { useState, useEffect } from "react";

const TableOfContents = ({ activeSection, activeSub }) => {
  const [sections, setSections] = useState([]);

  useEffect(() => {
    const content = document.getElementById("content");
    if (content) {
      const titles = content.querySelectorAll("h2");
      const sectionsData = Array.from(titles).map((title) => {
        const id =
          title.id || title.innerText.toLowerCase().replace(/\s+/g, "-");
        title.id = id;
        const subTitles = [];
        let element = title.nextElementSibling;
        while (element && element.tagName !== "H2") {
          if (element.tagName === "H3") {
            const subId =
              element.id ||
              element.innerText.toLowerCase().replace(/\s+/g, "-");
            element.id = subId;
            subTitles.push({ text: element.innerText, id: subId });
          }
          element = element.nextElementSibling;
        }
        return { text: title.innerText, id, subTitles };
      });
      setSections(sectionsData);
    }
  }, []);

  return (
    <div className="table-of-contents w-64">
      <h2 className="text-lg mb-4 text-gray-500">Contents</h2>
      <ul className="">
        {sections.map((section) => (
          <li key={section.id}>
            <a
              className={`text-gray-500 hover:text-primary border-l py-1 pl-2 ${section.text === activeSection ? "text-primary border-primary" : "border-gray-500"}`}
              href={`#${section.id}`}
            >
              {section.text}
            </a>
            {activeSection === section.text && section.subTitles.length > 0 && (
              <ul>
                {section.subTitles.map((subTitle) => (
                  <li key={subTitle.id}>
                    <a
                      className={`text-gray-500 hover:text-primary border-l py-1 pl-4 ${subTitle.text === activeSub ? "text-primary border-primary" : "border-gray-500"}`}
                      href={`#${subTitle.id}`}
                    >
                      {subTitle.text}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TableOfContents;
