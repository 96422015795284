import { useEffect, useState } from "react";
import TemplatePage from "../template/template";
import { GlobalApi } from "../../global";
import TableVault from "../../components/table";
import { decode } from "../../cryptho/encryption";
import { useNavigation } from "../../Redux/useNavigation";

const Vaults = () => {
  const { navigateTo } = useNavigation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [data, setData] = useState([]);

  const getVaults = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${GlobalApi()}/api/vault`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data.status === "error" && data.type === "jwt") {
        navigateTo("/login");
      }
      if (data.status === "error") {
        setError(data.error);
      }
      if (data.status === "ok" && data.folders) {
        const decode_folder = await decode(data.folders);
        setData(decode_folder);
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    getVaults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TemplatePage loading={loading} setLoading={setLoading}>
      <div className="home_selection_models w-full min-h-[calc(100vh-160px)] py-4 flex flex-col gap-4">
        <div className="flex items-start justify-between w-full">
          <div className="flex flex-col gap-2 mt-2">
            <h1 className="text-primary_token text-2xl">Vaults</h1>
            <p className="text-gray-600">Create and manage vaults env</p>
          </div>
          <div className="pr-2">
            <button
              onClick={() => navigateTo("/vaults/create")}
              className="px-4 py-2 bg-primary/80 hover:bg-primary/95 rounded-md mt-10"
            >
              New
            </button>
          </div>
        </div>
        {error && <p className="text-red-600">{error}</p>}
        {data && data.length > 0 && (
          <TableVault data={data} setData={setData} />
        )}
      </div>
    </TemplatePage>
  );
};

export default Vaults;
