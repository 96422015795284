import { useState } from "react";
import TemplatePage from "../template/template";

const CreateVaultTeams = () => {
  const [loading, setLoading] = useState(false);

  return (
    <TemplatePage loading={loading} setLoading={setLoading}>
      <div className="flex flex-col items-center gap-2">
        <img src="/Logo.png" alt="logo" className="w-14 h-14" />
        <h1 className="text-3xl text-primary_token">Welcome to your vault</h1>
      </div>
    </TemplatePage>
  );
};

export default CreateVaultTeams;
