const Loading = () => {
  return (
    <div className="flex items-center justify-center h-full">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
      <div className="flex items-center justify-center gap-2 animate-pulse">
        <p className="ml-4">Loading</p>
        <div className="rounded-full bg-primary/20 h-2 w-2"></div>
        <div className="rounded-full bg-primary/20 h-2 w-2"></div>
        <div className="rounded-full bg-primary/20 h-2 w-2"></div>
      </div>
    </div>
  );
};

export default Loading;
