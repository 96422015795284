import React, { useState } from "react";
import { List, arrayMove } from "react-movable";
import { PlusCircle, Trash2, Edit2, GripVertical, Link } from "lucide-react";
import TemplatePage from "../template/template";
import { GlobalApi } from "../../global";
import { useNavigate } from "react-router-dom";

const components = [
  "DocTitle",
  "Title",
  "SubTitle",
  "Content",
  "Console",
  "PromptInfo",
];

const consoleTypes = [
  "bash",
  "fish",
  "yaml",
  "shell",
  "javascript",
  "python",
  "json",
  "console",
  "conf",
];

const DocGenerator = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageName, setPageName] = useState("");
  const [selectedComponent, setSelectedComponent] = useState("Content");
  const [componentContent, setComponentContent] = useState("");
  const [editingIndex, setEditingIndex] = useState(null);
  const [showLinkInput, setShowLinkInput] = useState(false);
  const [linkUrl, setLinkUrl] = useState("");
  const [linkText, setLinkText] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [consoleType, setConsoleType] = useState("");

  const handleSubmit = async () => {
    try {
      setLoading(true);
      setError("");
      const response = await fetch(`${GlobalApi()}/api/docs/new`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ pageName, description, components: items }),
      });
      const data = await response.json();
      if (data.status === "error") {
        if (data.type === "jwt") {
          navigate("/login");
        }
        setError(data.error);
        setLoading(false);
        return;
      } else if (data.status === "ok") {
        navigate("/docs");
      }
    } catch (e) {
      setError(e.message);
    }
    setLoading(false);
  };

  const addItem = () => {
    if (selectedComponent && componentContent) {
      const newItem = { type: selectedComponent, content: componentContent };
      if (selectedComponent === "Console") {
        newItem.consoleType = consoleType;
      }
      setItems([...items, newItem]);
      setSelectedComponent("");
      setComponentContent("");
      setConsoleType("");
    }
  };
  const removeItem = (index) => {
    const newItems = items.filter((_, i) => i !== index);
    setItems(newItems);
  };

  const editItem = (index) => {
    setEditingIndex(index);
    setSelectedComponent(items[index].type);
    setComponentContent(items[index].content);
    if (items[index].type === "Console") {
      setConsoleType(items[index].consoleType || "");
    }
  };

  const updateItem = () => {
    if (editingIndex !== null && selectedComponent && componentContent) {
      const newItems = [...items];
      const updatedItem = {
        type: selectedComponent,
        content: componentContent,
      };
      if (selectedComponent === "Console") {
        updatedItem.consoleType = consoleType;
      }
      newItems[editingIndex] = updatedItem;
      setItems(newItems);
      setEditingIndex(null);
      setSelectedComponent("");
      setComponentContent("");
      setConsoleType("");
    }
  };

  const insertLink = () => {
    const newContent =
      componentContent + `<Ref href="${linkUrl}">${linkText}</Ref>`;
    setComponentContent(newContent);
    setShowLinkInput(false);
    setLinkUrl("");
    setLinkText("");
  };

  const renderContent = (content) => {
    const parts = content.split(/(<Ref.*?<\/Ref>)/);
    return parts.map((part, index) => {
      if (part.startsWith("<Ref")) {
        const hrefMatch = part.match(/href="(.*?)"/);
        const textMatch = part.match(/>(.+?)</);
        if (hrefMatch && textMatch) {
          return (
            <a
              key={index}
              href={hrefMatch[1]}
              className="text-primary hover:underline"
            >
              {textMatch[1]}
            </a>
          );
        }
      }
      return part;
    });
  };

  return (
    <TemplatePage loading={loading} setLoading={setLoading}>
      <div className="p-4 flex flex-1 flex-col overflow-auto scrollbar">
        <h1 className="text-2xl font-bold mb-4 text-gray-400">
          Documentation Generator
        </h1>
        {error && <p className="text-red-500 mb-4">{error}</p>}

        <div className="mb-4">
          <label htmlFor="pageName" className="block text-gray-400 mb-2">
            Page Name (for URL):
          </label>
          <input
            type="text"
            id="pageName"
            value={pageName}
            onChange={(e) => setPageName(e.target.value)}
            className="w-full p-2 border border-primary rounded bg-chat text-white mb-4"
            placeholder="Enter page name"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="description" className="block text-gray-400 mb-2">
            Description:
          </label>
          <textarea
            type="text"
            id="pageName"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-full p-2 border border-primary rounded bg-chat text-white mb-4"
            placeholder="Enter page name"
          />
        </div>

        <div className="mb-4 space-y-2">
          <select
            className="w-full p-2 border border-primary rounded bg-chat text-white"
            value={selectedComponent}
            onChange={(e) => setSelectedComponent(e.target.value)}
          >
            <option value="">Select a component</option>
            {components.map((comp) => (
              <option key={comp} value={comp}>
                {comp}
              </option>
            ))}
          </select>
          {selectedComponent === "Console" && (
            <select
              className="w-full p-2 border border-primary rounded bg-chat text-white"
              value={consoleType}
              onChange={(e) => setConsoleType(e.target.value)}
            >
              <option value="">Select console type</option>
              {consoleTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          )}
          <div className="relative">
            <textarea
              className="w-full p-2 border border-primary rounded bg-chat text-white"
              value={componentContent}
              onChange={(e) => setComponentContent(e.target.value)}
              placeholder="Enter component content"
              rows="3"
            />
            <button
              onClick={() => setShowLinkInput(!showLinkInput)}
              className="absolute top-2 right-2 text-primary"
            >
              <Link size={16} />
            </button>
          </div>
          {showLinkInput && (
            <div className="space-y-2">
              <input
                type="text"
                value={linkUrl}
                onChange={(e) => setLinkUrl(e.target.value)}
                placeholder="Enter link URL"
                className="w-full p-2 border border-primary rounded bg-chat text-white"
              />
              <input
                type="text"
                value={linkText}
                onChange={(e) => setLinkText(e.target.value)}
                placeholder="Enter link text"
                className="w-full p-2 border border-primary rounded bg-chat text-white"
              />
              <button
                onClick={insertLink}
                className="bg-primary text-white p-2 rounded"
              >
                Insert Link
              </button>
            </div>
          )}
          <div className="flex justify-between">
            <button
              className="bg-primary text-white p-2 rounded flex items-center hover:bg-primary/70 transition-colors"
              onClick={editingIndex !== null ? updateItem : addItem}
            >
              <PlusCircle className="mr-2" size={16} />
              {editingIndex !== null ? "Update" : "Add"} Component
            </button>
          </div>
        </div>

        <List
          values={items}
          onChange={({ oldIndex, newIndex }) =>
            setItems(arrayMove(items, oldIndex, newIndex))
          }
          renderList={({ children, props }) => <ul {...props}>{children}</ul>}
          renderItem={({ value, index, props }) => (
            <li
              {...props}
              className="flex items-center justify-between p-2 bg-base text-white rounded mb-2"
            >
              <div className="flex items-center w-full">
                <span className="mr-2 w-4 text-primary cursor-grab">
                  <GripVertical size={16} />
                </span>
                <span>
                  {value.type}: {renderContent(value.content)}
                </span>
              </div>
              <div className="w-12">
                <button
                  onClick={() => editItem(index)}
                  className="text-primary mr-2"
                >
                  <Edit2 size={16} />
                </button>
                <button
                  onClick={() => removeItem(index)}
                  className="text-red-500"
                >
                  <Trash2 size={16} />
                </button>
              </div>
            </li>
          )}
        />

        <div className="mt-4">
          <h2 className="text-xl text-gray-400 font-semibold mb-2">
            Generated JSON:
          </h2>
          <pre className="bg-base text-white p-2 rounded w-full overflow-x-auto scrollbar">
            {JSON.stringify(
              { pageName, description, components: items },
              null,
              2,
            )}
          </pre>
          <button
            type="button"
            onClick={() => handleSubmit()}
            className="bg-primary text-white mt-4 p-2 rounded flex items-center hover:bg-primary/70 transition-colors"
          >
            Submit
          </button>
        </div>
      </div>
    </TemplatePage>
  );
};

export default DocGenerator;
