import { useCallback, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import TemplatePage from "../template/template";
import { useDropzone } from "react-dropzone";
import { Upload, X } from "lucide-react";
import { GlobalApi } from "../../global";

const UpdateCloud = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [files, setFiles] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const path = searchParams.get("path") || "/home";

  const onDrop = useCallback((acceptedFiles) => {
    setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });

    formData.append("path", path);
    try {
      const response = await fetch(`${GlobalApi()}/api/cloud/upload`, {
        method: "POST",
        credentials: "include",
        body: formData,
      });
      const data = await response.json();
      if (data.status === "error") {
        if (data.type === "jwt") {
          navigate("/login");
        }
        setError(data.error);
      } else if (data.error) {
        setError(data.error);
      } else if (data.status === "ok") {
        navigate("/Cloud?path=" + path);
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
    setFiles([]);
  };

  const removeFile = (index) => {
    setFiles(files.filter((_, i) => i !== index));
  };

  return (
    <TemplatePage loading={loading} setLoading={setLoading}>
      <div className="home_selection_models w-full min-h-[calc(100vh-160px)] py-4 flex gap-4 flex-col">
        <div
          {...getRootProps()}
          className={`border-2 border-dashed border-primary rounded-lg p-8 text-center cursor-pointer w-full h-1/2 flex flex-col items-center justify-center ${
            isDragActive ? "border-primary bg-blue-50" : "border-gray-300"
          }`}
        >
          <h1 className="text-primary_token text-2xl py-4">Upload file</h1>
          <p className="text-gray-600">Upload new file</p>
          {error && <p className="text-red-600">{error}</p>}
          <input {...getInputProps()} />
          <Upload className="mx-auto h-12 w-12 text-gray-400" />
          <p className="mt-2 text-sm text-gray-600">
            {isDragActive
              ? "Slide the files here..."
              : "Slide the files here or click to select them manually"}
          </p>
        </div>
        {files.length > 0 && (
          <div className="mt-4 text-primary_token">
            <h3 className="text-lg font-semibold">Files selected:</h3>
            <ul className="list-disc pl-5">
              {files.map((file, index) => (
                <li key={index} className="flex items-center justify-between">
                  <span>{file.name}</span>
                  <button
                    onClick={() => removeFile(index)}
                    className="text-red-500 hover:text-red-700"
                  >
                    <X size={18} />
                  </button>
                </li>
              ))}
            </ul>
            <button
              onClick={handleSubmit}
              className="mt-4 px-4 py-2 bg-primary text-white rounded hover:bg-primary transition-colors"
            >
              Upload files
            </button>
          </div>
        )}
      </div>
    </TemplatePage>
  );
};

export default UpdateCloud;
