import { useEffect, useState } from "react";
import TemplatePage from "../template/template";
import { GlobalApi } from "../../global";
import LogoCenter from "../../components/logo_center";
import PopupDelete from "../../components/popupDelete";
import { decode } from "../../cryptho/encryption";
import { useNavigation } from "../../Redux/useNavigation";

const UserFolder = () => {
  const { navigateTo } = useNavigation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [secrets, setSecrets] = useState([]);
  const [popup, setPopup] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const getUser = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${GlobalApi()}/api/user/folder`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data.status === "error" && data.type === "jwt") {
        navigateTo("/login");
      }
      if (data.status === "error") {
        setError(data.error);
      }
      if (data.status === "ok") {
        const decrypted = await decode(data.folders);
        if (!decrypted) {
          setError("Error while decrypting data");
        } else {
          setSecrets(decrypted);
        }
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  const handleDelete = (id) => {
    setPopup(true);
    setDeleteId(id);
  };

  const handleApprouver = async () => {
    setPopup(false);
    try {
      const response = await fetch(
        `${GlobalApi()}/api/user/delete_folder/${deleteId}`,
        {
          method: "DELETE",
          credentials: "include",
        },
      );
      const data = await response.json();
      if (data.status === "error") {
        setError(data.error);
      }
      if (data.status === "ok") {
        setSecrets(secrets.filter((item) => item.FolderID !== deleteId));
      }
    } catch (e) {
      setError(e);
    }
  };

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TemplatePage loading={loading} setLoading={setLoading}>
      {popup && (
        <PopupDelete
          setPopup={setPopup}
          id={deleteId}
          handleDelete={handleApprouver}
          text={`Are you sure you want to delete this secret ${deleteId}`}
        />
      )}
      {error && <p className="text-red-500 text-sm my-3">{error}</p>}
      <div
        className={`w-full min-h-[calc(100vh-160px)] py-4 flex ${secrets.length > 0 ? "items-start" : "items-center justify-center"}`}
      >
        <div
          className={`flex flex-col ${secrets.length > 0 ? "items-start" : "items-center"} gap-4 w-full`}
        >
          {secrets.length > 0 ? (
            <>
              {error && <p className="text-red-500 text-sm my-3">{error}</p>}
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-primary_token/60 uppercase bg-base">
                  <tr>
                    <th scope="col" className="px-3 w-3/4 py-3">
                      Name
                    </th>
                    <th scope="col" className="px-3 w-1/4 py-3">
                      Delete
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {secrets &&
                    secrets.map((item) => (
                      <tr key={item.FolderID} className="bg-base/40">
                        <th
                          scope="row"
                          className="px-3 w-1/4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          {item.FolderName}
                        </th>
                        <td className="px-3 w-56 py-3 flex gap-2 items-center">
                          <button
                            type="button"
                            onClick={() => handleDelete(item.FolderID)}
                            className="text-primary_token px-4 py-2 bg-primary/80 hover:bg-primary/95 rounded-md"
                          >
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill="currentColor"
                                d="M7 21q-.825 0-1.412-.587T5 19V6H4V4h5V3h6v1h5v2h-1v13q0 .825-.587 1.413T17 21zM17 6H7v13h10zM9 17h2V8H9zm4 0h2V8h-2zM7 6v13z"
                              />
                            </svg>
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </>
          ) : (
            <>
              <LogoCenter />
              <p>No Folders</p>
            </>
          )}
          <button
            onClick={() => navigateTo("/admin/folder/new")}
            className="flex items-center justify-center px-4 py-2 text-lg font-semibold text-primary_token bg-base rounded-md shadow-md hover:bg-primary/30 focus:outline-none focus:ring-2 focus:ring-primary_token focus:ring-opacity-50 transition ease-in-out duration-150"
          >
            Create folder
          </button>
        </div>
      </div>
    </TemplatePage>
  );
};
export default UserFolder;
