import { createSlice } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

export const theme = createSlice({
  name: "theme",
  initialState: {
    theme: "dark",
  },
  reducers: {
    setTheme: (state, action) => {
      state.theme = action.payload;
    },
  },
});

export const useThemeDispatch = () => {
  const dispatch = useDispatch();
  const setTheme = (theme) => {
    dispatch(theme.actions.setTheme(theme));
  };
  return { setTheme };
};
