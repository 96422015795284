import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../components/header";
import { motion } from "framer-motion";
import Loading from "../components/loading";
import { GlobalApi } from "../global";

const ResetPasswordToken = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    checkToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePasswordChange = (e) => {
    const pass = e.target.value;
    setPassword(pass);

    // Check password strength
    let strength = 0;
    if (pass.length >= 8) strength += 1;
    if (/[A-Z]/.test(pass)) strength += 1;
    if (/[a-z]/.test(pass)) strength += 1;
    if (/[0-9]/.test(pass)) strength += 1;
    if (/[@$!%*?&]/.test(pass)) strength += 1;

    setPasswordStrength(strength);
  };

  const checkToken = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${GlobalApi()}/api/login/reset-password/${id}`,
        {
          method: "POST",
          credentials: "include",
        },
      );
      const data = await response.json();
      if (data.status === "error") {
        setError(data.error);
        setLoading(false);
        return;
      }
      if (data.status === "ok") {
        setSuccess(true);
        setLoading(false);
      }
    } catch (err) {
      setError("Login failed");
      setLoading(false);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!password) {
      setError("All fields are required");
      return;
    }
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("password", password);
      formData.append("token", id);
      const response = await fetch(`${GlobalApi()}/api/login/reset-password`, {
        method: "POST",
        credentials: "include",
        body: formData,
      });
      const data = await response.json();
      if (data.status === "error") {
        setError(data.error);
        setLoading(false);
        return;
      }
      if (data.status === "ok") {
        navigate("/login");
      }
    } catch (err) {
      setError("Login failed");
    }
    setLoading(false);
  };
  return (
    <>
      <Header isHome={false} />
      <div className="flex items-center justify-center min-h-screen bg-base">
        <motion.div
          animate={{ opacity: true ? 1 : 0, scale: true ? 1 : 0 }}
          transition={{ ease: "easeInOut", duration: 1 }}
          exit={{ opacity: 0, scale: 0 }}
          className="bg-chat  shadow-sm shadow-gray-700 rounded-md p-8 w-2/8 min-w-96 h-full"
        >
          <div className="p-8 font-brand text-center text-3xl bg-gradient-to-r from-pink-400 via-red-400 to-orange-400 text-transparent bg-clip-text">
            Reset Password
          </div>
          {loading && <Loading />}
          {!loading && success ? (
            <form id="login-form" onSubmit={handleSubmit}>
              <div id="error" className="text-red-500 text-sm my-3">
                <p>{error}</p>
              </div>
              <div className="flex flex-col space-y-4 gap-4">
                <div className="mb-4">
                  <input
                    type="password"
                    id="password"
                    name="password"
                    value={password}
                    onChange={(e) => handlePasswordChange(e)}
                    required
                    autoComplete="current-password"
                    placeholder="Password"
                    className="w-full bg-gray-[#121C22] text-gray-400 p-1 border rounded-md border-primary bg-chat focus:ring focus:outline-none"
                  />
                </div>
                <div className="w-full h-6 p-1 border-primary border rounded-full">
                  {passwordStrength === 0 && (
                    <div className={`h-full w-0 rounded-full`} />
                  )}
                  {passwordStrength === 1 && (
                    <div className={`h-full bg-red-400 w-1/5 rounded-full`} />
                  )}
                  {passwordStrength === 2 && (
                    <div className={`h-full bg-red-400 w-2/5 rounded-full`} />
                  )}
                  {passwordStrength === 3 && (
                    <div className={`h-full bg-red-400 w-3/5 rounded-full`} />
                  )}
                  {passwordStrength === 4 && (
                    <div className={`h-full bg-blue-500 w-4/5 rounded-full`} />
                  )}
                  {passwordStrength >= 5 && (
                    <div
                      className={`h-full bg-green-500 w-full rounded-full`}
                    />
                  )}
                </div>
              </div>
              <div className="my-4">
                <button
                  type="submit"
                  className="flex items-center gap-2 justify-center w-full bg-primary text-gray-700 font-bold text-md py-2 px-4 rounded-md hover:bg-primary/75 focus:outline-none focus:bg-gray-400 ease-out duration-75"
                >
                  Reset Password
                  {loading && (
                    <span className="spinner" id="spinner">
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    </span>
                  )}
                </button>
              </div>
            </form>
          ) : (
            <div className="text-red-500 text-sm">
              <p>Password token is Expired</p>
              <button
                type="button"
                onClick={() => navigate("/login")}
                className="flex mt-4 items-center gap-2 justify-center w-full bg-primary text-gray-700 font-bold text-md py-2 px-4 rounded-md hover:bg-primary/75 focus:outline-none focus:bg-gray-400 ease-out duration-75"
              >
                Back to login
                {loading && (
                  <span className="spinner" id="spinner">
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  </span>
                )}
              </button>
            </div>
          )}
        </motion.div>
      </div>
    </>
  );
};

export default ResetPasswordToken;
