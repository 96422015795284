const AdminItemMenu = [
  {
    title: "General",
    dropdown: false,
    items: [
      { title: "Api", link: "/admin/api" },
      { title: "Log", link: "/admin/log" },
      { title: "Folders", link: "/admin/folders" },
      { title: "Secrets", link: "/admin/secrets" },
      { title: "Settings", link: "/admin/settings" },
    ],
  },
  {
    title: "Users and Access",
    dropdown: false,
    items: [
      { title: "Teams", link: "/admin/teams" },
      { title: "Users", link: "/admin/users" },
    ],
  },
];

const VaulsItemMenu = [
  {
    title: "Vaults",
    dropdown: false,
    items: [
      { title: "Vaults", link: "/vaults" },
      { title: "Create", link: "/vaults/create" },
    ],
  },
  {
    title: "Teams vaults",
    dropdown: false,
    items: [
      { title: "Create", link: "/vaults_teams/create" },
      { title: "Views", link: "/vaults_teams/views" },
      { title: "Manage", link: "/vaults_teams/manage" },
    ],
  },
];

const CloudItemMenu = [
  {
    title: "Cloud",
    dropdown: false,
    items: [
      { title: "Storage", link: "/Cloud/Storage?path=/" },
      { title: "Uploads", link: "/Cloud/Uploads" },
    ],
  },
];

export { AdminItemMenu, VaulsItemMenu, CloudItemMenu };
