import { useEffect, useRef, useState } from "react";

const Dropdown = ({ title, selected, setSelected, list }) => {
  const [dropdown, setDropdown] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={dropdownRef}
      className="dropdown col-span-1 flex items-center justify-between gap-2 text-primary_token w-60 w-full"
    >
      {title}
      <button
        id="dropdownHoverButton"
        className="text-primary_token bg-base focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
        type="button"
        onClick={() => setDropdown(!dropdown)}
      >
        {selected}
        <svg
          className="w-2.5 h-2.5 ms-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 4 4 4-4"
          />
        </svg>
      </button>
      {list && dropdown && (
        <div
          id="dropdownHover"
          className="absolute z-10 text-primary_token divide-y rounded-lg shadow w-44 bg-base translate-y-12 translate-x-16"
        >
          <ul
            className="py-2 text-sm text-gray-700 dark:text-gray-200"
            aria-labelledby="dropdownHoverButton"
          >
            {list.map((option, index) => (
              <li key={index}>
                <button
                  className="w-full px-4 py-2 text-left hover:bg-gray-100 dark:hover:bg-gray-600 rounded-md"
                  onClick={() => {
                    setSelected(option);
                    setDropdown(false); // Close dropdown after selection
                  }}
                >
                  {option}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
