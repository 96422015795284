import React from "react";
import { useNavigate } from "react-router-dom";
import { Search, Edit2 } from "lucide-react";
import Pagination from "../paggination";

const DocumentList = ({
  documents,
  currentPage,
  totalPages,
  onPageChange,
  setCurrentPage,
  setItemsPerPage,
  itemsPerPage,
  searchTerm,
  setSearchTerm,
  isLoading,
}) => {
  const navigate = useNavigate();

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page on new search
  };

  return (
    <div className="flex flex-1 flex-col w-full h-full gap-4 pb-16">
      <div className="flex items-center justify-between top-0 z-10 p-4">
        <div className="relative w-full">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
          <input
            type="text"
            placeholder="Search documents..."
            value={searchTerm}
            onChange={handleSearch}
            className="w-full pl-10 pr-4 bg-gray-[#121C22] text-gray-400 h-8 p-1 border rounded-md border-primary bg-chat focus:ring focus:outline-none"
          />
        </div>
        <button
          type="button"
          onClick={() => navigate("/docs/new")}
          className="flex py-1 px-2 items-center gap-2 btn text-gray-300 bg-primary w-68 ml-4 rounded-md"
        >
          New
        </button>
      </div>
      {isLoading ? (
        <div className="flex justify-center items-center h-full">
          <p className="text-gray-300">Loading...</p>
        </div>
      ) : (
        <div className="flex h-full overflow-y-scroll scrollbar flex-col gap-4">
          {documents.map((doc, index) => (
            <div key={index} className="bg-base shadow rounded-lg p-4">
              <div className="flex items-center justify-between">
                <a
                  href={`/docs/${doc.Name}`}
                  className="flex gap-2 items-start flex-col"
                >
                  <h2 className="text-xl font-semibold mb-2 text-primary">
                    {doc.Name}
                  </h2>
                  <p className="text-gray-300">{doc.Description}</p>
                </a>
                <button
                  className="text-primary"
                  onClick={() => navigate(`/docs/update/${doc.Name}`)}
                >
                  <Edit2 size={16} />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
      <Pagination
        currentPage={currentPage}
        onPageChange={onPageChange}
        setItemsPerPage={setItemsPerPage}
        totalPages={totalPages}
        itemsPerPage={itemsPerPage}
      />
    </div>
  );
};

export default DocumentList;
