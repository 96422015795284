import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { navigate, goBack } from "./navigationSlice";

export const useNavigation = () => {
  const dispatch = useDispatch();
  const routerNavigate = useNavigate();
  const navigationState = useSelector((state) => state.navigation);
  // Vérification de sécurité
  const currentPath = navigationState ? navigationState.currentPath : "/";
  const previousPath = navigationState ? navigationState.previousPath : null;

  const navigateTo = (path) => {
    if (path !== "/login") {
      dispatch(navigate(path));
    }
    routerNavigate(path);
  };

  const navigateBack = () => {
    if (previousPath) {
      dispatch(goBack());
      routerNavigate(previousPath);
    } else {
      dispatch(navigate("/"));
      routerNavigate("/");
    }
  };

  return { navigateTo, navigateBack, currentPath, previousPath };
};
