import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import Login from "./pages/login";
import Register from "./pages/register";
import CreateVault from "./pages/vault/create";
import TeamsAdmin from "./pages/team/teams";
import TeamsAdminNew from "./pages/team/new";
import Edit from "./pages/team/edit";
import ManageVault from "./pages/vault/manage";
import ViewsVaultTeams from "./pages/vault_teams/Views_teams";
import CreateVaultTeams from "./pages/vault_teams/create_teams";
import ManageVaultTeams from "./pages/vault_teams/manage_teams";
import Useradmin from "./pages/admin/user";
import Settings from "./pages/admin/settings";
import NewSecret from "./pages/admin/createSecret";
import UserFolder from "./pages/admin/folders";
import NewFolder from "./pages/admin/createFolder";
import Vaults from "./pages/vault/vaults";
import VauldEdit from "./pages/vault/edit";
import VaultView from "./pages/vault/Views";
import ResetPassword from "./pages/reset";
import ResetPasswordToken from "./pages/resetPassword";
import Api from "./pages/admin/api";
import NewApi from "./pages/admin/newApi";
import NewSecretCustom from "./pages/admin/createsecretCustom";
import UpdateCloud from "./pages/cloud/upload";
import Cloud from "./pages/cloud/storage";
import Log from "./pages/admin/log";
import { NavigationProvider } from "./Redux/redux";
import TwoFa from "./pages/twofa/twofa";
import DocumentListPage from "./pages/Docs/home";
import DocGenerator from "./pages/Docs/genDoc";
import DynamicDocRender from "./pages/Docs/dynamicRender";
import UpdateDocGenerator from "./pages/Docs/update";
import PdfView from "./components/Docs/PdfView";

function App() {
  return (
    <BrowserRouter>
      <div id="llm-app" className="bg-base min-h-screen w-screen">
        <div className="absolute z-20 top-0 inset-x-0 flex justify-center overflow-hidden pointer-events-none">
          <div className="w-[108rem] flex-none flex justify-end">
            <picture>
              <source srcSet="/effect.avif" type="image/avif" />
              <img
                src="/effect.avif"
                alt=""
                className="w-[71.75rem] flex-none max-w-none opacity-40"
                decoding="async"
              />
            </picture>
          </div>
        </div>
        <NavigationProvider>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/admin/log" element={<Log />} />
            <Route path="/admin/api" element={<Api />} />
            <Route path="/admin/api/new" element={<NewApi />} />
            <Route path="/admin/secrets" element={<Useradmin />} />
            <Route path="/admin/secrets/new" element={<NewSecret />} />
            <Route path="/admin/teams" element={<TeamsAdmin />} />
            <Route
              path="/admin/secrets/new_custom"
              element={<NewSecretCustom />}
            />
            <Route path="/admin/folders" element={<UserFolder />} />
            <Route path="/admin/folder/new" element={<NewFolder />} />
            <Route path="/admin/settings" element={<Settings />} />
            <Route path="/admin/teams/edit/:id" element={<Edit />} />
            <Route path="/admin/teams/new" element={<TeamsAdminNew />} />
            {/* Docs */}
            <Route path="/docs" element={<DocumentListPage />} />
            <Route path="/docs/new" element={<DocGenerator />} />
            <Route path="/docs/:docname" element={<DynamicDocRender />} />
            <Route
              path="/docs/update/:docname"
              element={<UpdateDocGenerator />}
            />
            <Route path="/twofa" element={<TwoFa />} />
            <Route path="/cloud" element={<Cloud />} />
            <Route path="/cloud/uploads" element={<UpdateCloud />} />
            <Route path="/cloud/pdf/:folder/:filename" element={<PdfView />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/vaults" element={<Vaults />} />
            <Route path="/vaults/create" element={<CreateVault />} />
            <Route path="/vaults/edit/:id/:folder" element={<VauldEdit />} />
            <Route path="/vaults/view/:id/:folder" element={<VaultView />} />
            <Route path="/vaults/manage" element={<ManageVault />} />
            <Route path="/vaults_teams/create" element={<CreateVaultTeams />} />
            <Route path="/vaults_teams/views" element={<ViewsVaultTeams />} />
            <Route path="/vaults_teams/manage" element={<ManageVaultTeams />} />
            <Route path="/reset" element={<ResetPassword />} />
            <Route
              path="/reset-password/:id"
              element={<ResetPasswordToken />}
            />
            <Route path="*" element={<Home />} />
          </Routes>
        </NavigationProvider>
      </div>
    </BrowserRouter>
  );
}

export default App;
