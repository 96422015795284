import { useEffect, useState } from "react";
import TemplatePage from "../template/template";
import { GlobalApi } from "../../global";
import { useNavigation } from "../../Redux/useNavigation";
import Dropdown from "../../components/dropdown";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

const TwoFa = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [newFa, setNewFa] = useState(false);
  const [url, setUrl] = useState("");
  const [errorAdd, setErrorAdd] = useState("");
  const { navigateTo } = useNavigation();
  const [fa, setFa] = useState([]);
  const [faSelected, setFaSelected] = useState("");
  const [code, setCode] = useState([]);
  const [period, setPeriod] = useState(0);
  const [copy, setCopy] = useState(false);

  useEffect(() => {
    const getFa = async () => {
      try {
        const response = await fetch(`${GlobalApi()}/api/twofa/get_fa`, {
          method: "GET",
          credentials: "include",
        });
        const data = await response.json();
        if (data.status === "error" && data.type === "jwt") {
          navigateTo("/login");
        }
        if (data.status === "error") {
          setErrorAdd(data.error);
        } else if (data.status === "ok") {
          setFa(data.fa);
        }
      } catch (e) {
        console.error(e);
      }
    };
    getFa();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getFaCode();
    // eslint-disable-next-line
  }, [faSelected]);

  useEffect(() => {
    if (period > 0) {
      const interval = setInterval(() => {
        setPeriod((prevPeriod) => {
          if (prevPeriod <= 1) {
            getFaCode(); // Régénère le code quand la période est expirée
            return code.period; // Réinitialise la période
          }
          return prevPeriod - 1; // Décrémente period de 1
        });
      }, 1000);

      return () => clearInterval(interval); // Nettoie l'intervalle
    } else {
      getFaCode();
    }
    // eslint-disable-next-line
  }, [period, code.period]);

  const getFaCode = async () => {
    setLoading(true);
    setError("");
    if (faSelected === "") {
      setLoading(false);
      return;
    }
    try {
      const formData = new FormData();
      formData.append("issuer", faSelected);
      const response = await fetch(`${GlobalApi()}/api/twofa/code`, {
        method: "POST",
        credentials: "include",
        body: formData,
      });
      const data = await response.json();
      if (data.status === "error" && data.type === "jwt") {
        navigateTo("/login");
      }
      if (data.status === "error") {
        setErrorAdd(data.error);
      } else if (data.status === "ok") {
        setCode(data.fa);
        setPeriod(data.fa.period);
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
    setError("");
  };

  const submitNewFa = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorAdd("");
    if (!url || url === "") {
      setErrorAdd("All fields are required");
      setLoading(false);
      return;
    }
    try {
      const formData = new FormData();
      formData.append("code", url);
      const response = await fetch(`${GlobalApi()}/api/twofa/new`, {
        method: "POST",
        credentials: "include",
        body: formData,
      });
      const data = await response.json();
      if (data.status === "error" && data.type === "jwt") {
        navigateTo("/login");
      }
      if (data.status === "error") {
        setErrorAdd(data.error);
      } else if (data.status === "ok") {
        setNewFa(false);
        setUrl("");
        setErrorAdd("");
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (period > 0) {
      const interval = setInterval(() => {
        setPeriod((prevPeriod) => prevPeriod - 1); // Décrémente period de 1
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [period]);

  const copyClipboard = () => {
    navigator.clipboard
      .writeText(code.code)
      .then(() => {
        setCopy(true);
      })
      .catch(() => {
        alert("Failed to copy!");
      });
    setTimeout(() => {
      setCopy(false);
    }, 2000);
  };

  return (
    <TemplatePage loading={loading} setLoading={setLoading}>
      <div className="scrollbar flex-grow home_selection_models w-full max-h-[calc(100vh-160px)] py-4 flex flex-col gap-4 overflow-y-scroll">
        {newFa && (
          <div className="fixed top-0 left-0 z-50 flex justify-center items-center h-screen w-screen bg-transparent">
            <form
              onSubmit={submitNewFa}
              className="relative p-4 w-full max-w-md max-h-full flex flex-col gap-2 bg-base shadow-white/10 shadow-sm rounded-lg text-gray-400"
            >
              {errorAdd && <p className="text-red-600">{errorAdd}</p>}
              <h1>Add new TwoFA</h1>
              <input
                value={url}
                placeholder="Enter the url"
                onChange={(e) => setUrl(e.target.value)}
                className="input input-bordered w-full bg-gray-600 rounded-lg p-2 text-black"
              />
              <button className="px-4 py-2 bg-primary/80 hover:bg-primary/95 rounded-md mt-2">
                add
              </button>
            </form>
          </div>
        )}
        <div className="flex items-start justify-between w-full">
          <div className="flex flex-col gap-2 mt-2">
            <h1 className="text-primary_token text-2xl">Cloud</h1>
            <p className="text-gray-600">TwoFA</p>
          </div>
          <div className="pr-2 flex gap-2">
            <button
              onClick={() => setNewFa(true)}
              className="px-4 py-2 bg-primary/80 hover:bg-primary/95 rounded-md mt-10"
            >
              New TwoFa
            </button>
          </div>
        </div>
        {error && <p className="text-red-600">{error}</p>}
        <span className="w-full pr-2">
          <Dropdown
            title="Select TwoFA"
            selected={faSelected}
            setSelected={setFaSelected}
            list={fa}
          />
        </span>
        <div className="relative flex flex-col gap-2 mt-2 w-full h-full bg-base/20 rounded-lg items-center justify-center text-gray-400">
          {code && code.period && (
            <>
              {copy && (
                <p className="absolute top-2 right-4 text-primary">Copied</p>
              )}
              <p>{code.issuer}</p>
              <p>You token is:</p>
              <button
                className="text-primary flex"
                type="button"
                onClick={() => copyClipboard()}
              >
                <p className="text-3xl">{code.code}</p>
              </button>
              <div className="flex items-center gap-2">
                <p>You token will expire in {period}s</p>
              </div>
            </>
          )}
        </div>
      </div>
    </TemplatePage>
  );
};

export default TwoFa;
