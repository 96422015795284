import { useEffect, useState } from "react";
import { GlobalApi } from "../global";
import Loading from "../components/loading";
import KeyAnimation from "../components/motion_key_animation";
import { motion } from "framer-motion";
import Header from "../components/header";
import { useNavigation } from "../Redux/useNavigation";

const Form = ({ ApprouvedAnimation }) => {
  const { navigateTo } = useNavigation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [secret_key, setSecret_key] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const [session, setSession] = useState(false);

  const checkCredentials = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${GlobalApi()}/api/login/credentials`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data.status === "error") {
        setLoading(false);
        return;
      }
      const new_data = data.data;
      if (new_data.session) {
        setEmail(new_data.session.email);
        setSession(true);
        setSecret_key(new_data.session.secret);
      }
    } catch (_) {}
    setLoading(false);
  };
  useEffect(() => {
    checkCredentials();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      setError("All fields are required");
      return;
    }
    if (session && (!email || !password || !secret_key)) {
      setError("All fields are required");
      return;
    }
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("email", email);
      formData.append("password", password);
      formData.append("secret_key", secret_key);
      const response = await fetch(`${GlobalApi()}/api/login`, {
        method: "POST",
        credentials: "include",
        body: formData,
      });
      const data = await response.json();
      if (data.status === "error") {
        setError(data.error);
        setLoading(false);
        return;
      }
      if (data.status === "ok") {
        ApprouvedAnimation();
      }
    } catch (err) {
      setError("Login failed");
    }
    setLoading(false);
  };
  return (
    <form id="login-form" onSubmit={handleSubmit}>
      {loading && <Loading />}
      {!loading && (
        <>
          <div id="error" className="text-red-500 text-sm my-3">
            <p>{error}</p>
          </div>
          <div className="flex flex-col space-y-4 gap-4">
            {session && (
              <div className="flex gap-2">
                <img
                  src="/avatar-group-default.png"
                  alt="waxcoin"
                  className="w-20 h-20 mb-4 rounded-lg"
                />
                <div className="w-full mb-4 text-primary_token flex flex-col gap-1">
                  <p>Connect to </p>
                  <p>{email}</p>
                  <p>{secret_key}</p>
                </div>
              </div>
            )}
            {!session && (
              <div className="w-full mb-4">
                <input
                  autoCapitalize="none"
                  autoCorrect="off"
                  type="text"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  placeholder="Email"
                  autoComplete="email"
                  autoFocus
                  className="w-full bg-gray-[#121C22] text-gray-400 p-1 border rounded-md border-primary bg-chat focus:ring focus:outline-none"
                />
              </div>
            )}
          </div>
          {!session && (
            <div className="mb-4">
              <input
                type="password"
                id="secret_key"
                name="secret_key"
                value={secret_key}
                onChange={(e) => setSecret_key(e.target.value)}
                required
                placeholder="Secret key"
                className="w-full bg-gray-[#121C22] text-gray-400 p-1 border rounded-md border-primary bg-chat focus:ring focus:outline-none"
              />
            </div>
          )}
          <div className="mb-4">
            <input
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              autoComplete="current-password"
              placeholder="Password"
              className="w-full bg-gray-[#121C22] text-gray-400 p-1 border rounded-md border-primary bg-chat focus:ring focus:outline-none"
            />
          </div>
          <div className="mb-4">
            <button
              type="submit"
              className="flex items-center gap-2 justify-center w-full bg-primary text-gray-700 font-bold text-md py-2 px-4 rounded-md hover:bg-primary/75 focus:outline-none focus:bg-gray-400 ease-out duration-75"
            >
              login
              {loading && (
                <span className="spinner" id="spinner">
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </span>
              )}
            </button>
          </div>
          <div className="text-primary_token flex items-center justify-between text-sm">
            <button
              className="hover:text-primary hover:underline underline-offset-1 ease-in-out duration-75"
              type="button"
              onClick={() => navigateTo("/register")}
            >
              Create an account
            </button>
            <button
              className="hover:text-primary hover:underline underline-offset-1 ease-in-out duration-75"
              onClick={() => navigateTo("/reset")}
              type="button"
            >
              Reset password?
            </button>
          </div>
        </>
      )}
    </form>
  );
};

const Login = () => {
  const [active, setActive] = useState(false);
  const [hidden, setHidden] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const [keyAnimation, setKeyAnimation] = useState(false);
  const { navigateTo } = useNavigation();

  useEffect(() => {
    setTimeout(() => {
      setHidden(false);
    }, 999);
    setTimeout(() => {
      setIsVisible(!isVisible);
    }, 1000);
    setTimeout(() => {
      setActive(!active);
    }, 2500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ApprouvedAnimation = () => {
    setTimeout(() => {
      setHidden(true);
    }, 600);
    setTimeout(() => {
      setActive(false);
    }, 1000);
    setTimeout(() => {
      setIsVisible(true);
      setHidden(false);
    }, 2000);
    setTimeout(() => {
      setKeyAnimation(!keyAnimation);
    }, 3000);
    setTimeout(() => {
      navigateTo("/");
    }, 3500);
  };

  return (
    <>
      <Header isMenu={false} />
      <div className="flex items-center justify-center min-h-screen bg-base">
        <motion.div
          animate={{ opacity: active ? 1 : 0, scale: active ? 1 : 0 }}
          transition={{ ease: "easeInOut", duration: 1 }}
          exit={{ opacity: 0, scale: 0 }}
          className="bg-chat  shadow-sm shadow-gray-700 rounded-md p-8 w-2/8 min-w-96 h-full"
        >
          <div className="p-8 font-brand text-center text-3xl bg-gradient-to-r from-pink-400 via-red-400 to-orange-400 text-transparent bg-clip-text">
            Login
          </div>
          <Form ApprouvedAnimation={ApprouvedAnimation} />
        </motion.div>
        {!active && (
          <KeyAnimation
            isVisible={isVisible}
            KeyAnimation={keyAnimation}
            setKeyAnimation={setKeyAnimation}
          />
        )}
        {hidden && (
          <div className="flex items-center justify-center bg-base absolute z-99 h-screen w-screen ">
            {" "}
          </div>
        )}
      </div>
    </>
  );
};

export default Login;
