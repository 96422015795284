import { useState } from "react";
import { useNavigation } from "../Redux/useNavigation";

const SideBarMenu = ({ item, title, SVG }) => {
  const [dropdown, setDropdown] = useState(false);
  const [itemMenu, setItemMenu] = useState(item);
  const { navigateTo } = useNavigation();

  const handleDropdown = (index) => {
    const updatedItems = itemMenu.map((item, i) => {
      if (i === index) {
        return { ...item, dropdown: !item.dropdown };
      } else {
        return { ...item, dropdown: false };
      }
    });
    setItemMenu(updatedItems);
  };

  return (
    <nav className="w-full p-2 flex-1 gap-2">
      <div id="sideBar" className="flex flex-col">
        <button
          id="dropdownDefaultButton"
          onClick={() => setDropdown(!dropdown)}
          className={`flex items-center justify-between text-primary_token ${dropdown && "bg-chat"} hover:bg-chat font-medium rounded-lg text-sm px-5 py-2.5 text-center`}
          type="button"
        >
          <div className="flex items-center gap-2">
            <SVG />
            {title}
          </div>
          <svg
            className="w-2.5 h-2.5 ms-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 4 4 4-4"
            ></path>
          </svg>
        </button>
        {dropdown && (
          <>
            {itemMenu.map((item, index) => (
              <div
                key={index}
                id="dropdown"
                className="w-full ml-6 pl-4 pr-8 border border-l-black py-2 border-base"
              >
                <ul
                  className="flex hover:text-primary_token cursor-pointer flex-col items-start w-full p-2 text-sm text-gray-700 dark:text-gray-200"
                  onClick={() => handleDropdown(index)}
                >
                  <li className="w-full">
                    <div className="flex items-center justify-between">
                      {item.title}
                      <svg
                        className="w-2.5 h-2.5 ms-3"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m1 1 4 4 4-4"
                        ></path>
                      </svg>
                    </div>
                    {item.dropdown && (
                      <div className="ml-1 mt-2">
                        {item.items.map((subItem, idx) => (
                          <div
                            key={idx}
                            id="dropdown"
                            className="w-full pl-4 pr-8 border border-l-black text-gray-600 py-2 border-base hover:underline underline-offset-2"
                          >
                            <button
                              key={idx}
                              type="button"
                              onClick={() => navigateTo(subItem.link)}
                              className="py-2 text-sm"
                            >
                              {subItem.title}
                            </button>
                          </div>
                        ))}
                      </div>
                    )}
                  </li>
                </ul>
              </div>
            ))}
          </>
        )}
      </div>
    </nav>
  );
};

export default SideBarMenu;
