import { useEffect, useState } from "react";
import TemplatePage from "../template/template";
import { useNavigate, useParams } from "react-router-dom";
import { GlobalApi } from "../../global";
import Button from "../../components/button";
import { decode } from "../../cryptho/encryption";

const VaultEdit = () => {
  const { id, folder } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [content, setContent] = useState("");
  const [key, setKey] = useState("");

  useEffect(() => {
    getEnv();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getEnv = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${GlobalApi()}/api/vault/env/edit/${id}/${folder}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      const data = await response.json();
      if (data.status === "error" && data.type === "jwt") {
        navigate("/login");
      }
      if (data.status === "error") {
        setError(data.error);
      }
      if (data.status === "ok" && data.env_decode) {
        const dec = await decode(data.env_decode);
        setName(dec.FileName);
        setDescription(dec.FileDesc);
        setContent(dec.FileContent);
        setKey(dec.SecretID);
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!name) {
      setError("All fields are required");
      return;
    }
    setLoading(true);
    setError("");
    try {
      const formData = new FormData();
      formData.append("id", id);
      formData.append("folder_id", folder);
      formData.append("name", name);
      formData.append("description", description);
      formData.append("content", content);
      formData.append("key", key);
      const response = await fetch(`${GlobalApi()}/api/vault/edit`, {
        method: "POST",
        credentials: "include",
        body: formData,
      });
      const data = await response.json();
      if (data.status === "error") {
        if (data.type === "jwt") {
          navigate("/login");
        }
        setError(data.error);
      } else {
        getEnv();
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  return (
    <TemplatePage loading={loading} setLoading={setLoading}>
      <div className="home_selection_models w-full min-h-[calc(100vh-160px)] py-4 flex gap-4">
        <form onSubmit={handleSubmit} className="w-full">
          <h1 className="text-primary_token text-2xl py-4">Edit env</h1>
          <p className="text-gray-600">Edit env.</p>
          {error && <p className="text-red-600">{error}</p>}
          <div className="w-full flex flex-col gap-4 mt-4">
            <input
              autoCapitalize="none"
              autoCorrect="off"
              type="text"
              id="name"
              name="name"
              placeholder="name"
              onChange={(e) => setName(e.target.value)}
              value={name}
              className="w-full bg-gray-[#121C22] text-gray-400 h-8 p-1 border rounded-md border-primary bg-chat focus:ring focus:outline-none"
            />
            <textarea
              type="text"
              id="content"
              name="content"
              placeholder={"key=value"}
              rows={20}
              maxLength={1024}
              onChange={(e) => setContent(e.target.value)}
              value={content}
              className="w-full bg-gray-[#121C22] text-gray-400 min-h-28 p-1 border rounded-md border-primary bg-chat focus:ring focus:outline-none"
            />
            <textarea
              autoCapitalize="none"
              autoCorrect="off"
              type="text"
              placeholder="desc"
              id="env_desc"
              name="desc"
              rows={10}
              maxLength={255}
              onChange={(e) => setDescription(e.target.value)}
              value={description}
              className="w-full bg-gray-[#121C22] text-gray-400 min-h-10 p-1 border rounded-md border-primary bg-chat focus:ring focus:outline-none"
            />
          </div>
          <Button>Edit</Button>
        </form>
      </div>
    </TemplatePage>
  );
};

export default VaultEdit;
