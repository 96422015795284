import { useNavigate, useParams } from "react-router-dom";
import TemplatePage from "../../pages/template/template";
import { useEffect, useState } from "react";
import { GlobalApi } from "../../global";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

const PdfView = () => {
  const { filename, folder } = useParams();
  const [loading, setLoading] = useState(false);
  const [urlPdf, setUrlPdf] = useState(null);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [numPages, setNumPages] = useState(null);

  pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.mjs`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${GlobalApi()}/api/cloud/preview_pdf/${folder}/${filename}`,
          {
            method: "GET",
            credentials: "include",
          },
        );
        const data = await response.json();
        if (data.status === "error" && data.type === "jwt") {
          navigate("/login");
        }
        if (data.status === "error") {
          setError(data.error);
        } else if (data.status === "ok") {
          setUrlPdf(data.url);
        }
      } catch (_) {
        setError("An error occurred while fetching the PDF.");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [filename, folder, navigate]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <TemplatePage loading={loading} setLoading={setLoading}>
      {error && <div className="text-red-500">{error}</div>}
      <div className="w-full h-full overflow-y-auto scrollbar p-8">
        <Document file={urlPdf} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
          ))}
        </Document>
      </div>
    </TemplatePage>
  );
};

export default PdfView;
