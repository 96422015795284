import { configureStore } from "@reduxjs/toolkit";
import { theme } from "./theme";
import navigationSlice from "./navigationSlice";
import { Provider } from "react-redux";

export const store = configureStore({
  reducer: {
    theme: theme.reducer,
    navigation: navigationSlice,
  },
});

export const NavigationProvider = ({ children }) => {
  return <Provider store={store}>{children}</Provider>;
};
