import CryptoJS from "crypto-js";
import { GlobalApi } from "../global";

let encryptionKeyCache = null;
let encryptionKeyCacheExpiry = 0;

const getKey = async () => {
  const now = Date.now();
  if (encryptionKeyCache && now < encryptionKeyCacheExpiry) {
    return encryptionKeyCache;
  }

  try {
    const response = await fetch(`${GlobalApi()}/api/encryption`, {
      method: "POST",
      credentials: "include",
    });
    const data = await response.json();
    if (data.error) {
      return null;
    }
    encryptionKeyCache = data;
    encryptionKeyCacheExpiry = now + 3600000;
    return data;
  } catch (error) {}
  return null;
};

const decryptedCache = new Map();

export const decode = async (encryptedText) => {
  if (decryptedCache.has(encryptedText)) {
    return decryptedCache.get(encryptedText);
  }
  try {
    const data = await getKey();
    if (!data) {
      return null;
    }
    const iv = CryptoJS.enc.Utf8.parse(data.iv);
    const key = CryptoJS.enc.Utf8.parse(data.key);
    const decrypted = CryptoJS.AES.decrypt(encryptedText, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    const decryptedString = decrypted.toString(CryptoJS.enc.Utf8);

    // Parse la chaîne JSON en objet JavaScript
    //
    const result = JSON.parse(decryptedString);
    decryptedCache.set(encryptedText, result);
    return result;
  } catch (error) {
    console.error("Erreur lors du déchiffrement ou du parsing:", error);
    return null; // ou throw error; selon votre gestion d'erreurs préférée
  }
};
