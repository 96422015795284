import { useEffect, useState } from "react";
import { GlobalApi } from "../../global";
import Button from "../../components/button";
import TemplatePage from "../template/template";
import { decode } from "../../cryptho/encryption";
import Dropdown from "../../components/dropdown";
import { useNavigation } from "../../Redux/useNavigation";

const NewApi = () => {
  const { navigateTo } = useNavigation();
  const [loading, setLoading] = useState(false);
  const [secretName, setSecretName] = useState("");
  const [error, setError] = useState("");
  const [keys, setKeys] = useState([]);
  const [key, setKey] = useState("key");

  useEffect(() => {
    getKeys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getKeys = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${GlobalApi()}/api/user`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data.status === "error" && data.type === "jwt") {
        navigateTo("/login");
      }
      if (data.status === "error") {
        setError(data.error);
      }
      if (data.status === "ok") {
        const dec = await decode(data.secrets);
        setKeys([]);
        setKeys((prevKeys) => [...prevKeys, ...dec.map((item) => item.Name)]);
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!secretName && secretName.length > 30) {
      setError("All fields are required");
    }
    setLoading(true);
    setError("");
    try {
      const formData = new FormData();
      formData.append("name", secretName);
      formData.append("key", key);
      const response = await fetch(`${GlobalApi()}/api/new`, {
        method: "POST",
        credentials: "include",
        body: formData,
      });
      const data = await response.json();
      if (data.status === "error") {
        if (data.type === "jwt") {
          navigateTo("/login");
        }
        setError(data.error);
      } else {
        navigateTo("/admin/api");
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  return (
    <TemplatePage loading={loading} setLoading={setLoading}>
      <div className="home_selection_models w-full min-h-[calc(100vh-160px)] py-4 flex gap-4">
        <form onSubmit={handleSubmit} className="w-full flex flex-col gap-4">
          <h1 className="text-primary_token text-2xl py-4">New api</h1>
          <p className="text-gray-600">Create a new api key.</p>
          {error && <p className="text-red-600">{error}</p>}
          <div className="w-full grid grid-cols-3 gap-4">
            <Dropdown
              title={""}
              selected={key}
              setSelected={setKey}
              list={keys}
            />
          </div>
          <p className="text-primary_token pt-2 pb-1">Name</p>
          <input
            autoCapitalize="none"
            autoCorrect="off"
            type="text"
            id="name"
            name="name"
            onChange={(e) => setSecretName(e.target.value)}
            value={secretName}
            className="w-full bg-gray-[#121C22] text-gray-400 h-12 p-1 border rounded-md border-primary bg-chat focus:ring focus:outline-none"
          />
          <Button>Create</Button>
        </form>
      </div>
    </TemplatePage>
  );
};

export default NewApi;
