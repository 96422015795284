import { Folder } from "lucide-react";
import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import { FaFileAudio, FaFileVideo } from "react-icons/fa";
import { GlobalApi } from "../global";
import MenuCloud from "./memuCloud";
import Loading from "./loading";

const FileIcon = ({ type, size }) => {
  const format = type.split("/")[0];
  const typefile = type.split("/")[1];
  switch (format) {
    case "image":
      return (
        <svg
          class="k2eJge"
          className={`w-${size} h-${size} text-red-400`}
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="fill-current"
            d="M16 14.222V1.778C16 .796 15.204 0 14.222 0H1.778C.796 0 0 .796 0 1.778v12.444C0 15.204.796 16 1.778 16h12.444c.982 0 1.778-.796 1.778-1.778zM4.889 9.333l2.222 2.671L10.222 8l4 5.333H1.778l3.11-4z"
          ></path>
        </svg>
      );
    case "audio":
      return <FaFileAudio />;
    case "video":
      return <FaFileVideo />;
    case "application":
      switch (typefile) {
        case "pdf":
          return (
            <svg
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
              className={`w-${size} h-${size} text-red-500`}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                className="fill-current"
                d="M1.778 0h12.444C15.2 0 16 .8 16 1.778v12.444C16 15.2 15.2 16 14.222 16H1.778C.8 16 0 15.2 0 14.222V1.778C0 .8.8 0 1.778 0zm2.666 7.556h-.888v-.89h.888v.89zm1.334 0c0 .737-.596 1.333-1.334 1.333h-.888v1.778H2.222V5.333h2.222c.738 0 1.334.596 1.334 1.334v.889zm6.666-.89h2.223V5.334H11.11v5.334h1.333V8.889h1.334V7.556h-1.334v-.89zm-2.222 2.667c0 .738-.595 1.334-1.333 1.334H6.667V5.333h2.222c.738 0 1.333.596 1.333 1.334v2.666zm-1.333 0H8V6.667h.889v2.666z"
              ></path>
            </svg>
          );
        default:
          return (
            <svg
              class="auHQVc"
              className={`w-${size} h-${size} text-blue-500`}
              viewBox="0 0 12 16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className="fill-current"
                d="M8 0H1.6C.72 0 .008.72.008 1.6L0 14.4c0 .88.712 1.6 1.592 1.6H11.2c.88 0 1.6-.72 1.6-1.6V4.8L8 0zm1.6 12.8H3.2v-1.6h6.4v1.6zm0-3.2H3.2V8h6.4v1.6zm-2.4-4V1.2l4.4 4.4H7.2z"
              ></path>
            </svg>
          );
      }
    default:
      return (
        <svg
          class="auHQVc"
          className={`w-${size} h-${size} text-blue-400`}
          viewBox="0 0 12 16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M8 0H1.6C.72 0 .008.72.008 1.6L0 14.4c0 .88.712 1.6 1.592 1.6H11.2c.88 0 1.6-.72 1.6-1.6V4.8L8 0zm1.6 12.8H3.2v-1.6h6.4v1.6zm0-3.2H3.2V8h6.4v1.6zm-2.4-4V1.2l4.4 4.4H7.2z"></path>
        </svg>
      );
  }
};

const ConditionalImage = ({ file, folder }) => {
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  const imageSrc = file?.MinioObjectNameThumbnail
    ? `${GlobalApi()}/api/cloud/preview/${folder}/${file.MinioObjectNameThumbnail}`
    : "";

  if (!imageSrc || imageError) {
    return (
      <div className="w-full h-48 rounded-md object page flex items-center justify-center">
        <FileIcon type={file?.ContentType} size={12} />
      </div>
    );
  }
  return (
    <img
      src={imageSrc}
      alt={file?.Name || "Preview"}
      onError={handleImageError}
      className="w-full h-48 rounded-md object page"
    />
  );
};

// eslint-disable-next-line no-unused-vars
const formatFileSize = (bytes) => {
  if (bytes < 1024) return bytes + " B";
  else if (bytes < 1048576) return (bytes / 1024).toFixed(2) + " KB";
  else if (bytes < 1073741824) return (bytes / 1048576).toFixed(2) + " MB";
  else return (bytes / 1073741824).toFixed(2) + " GB";
};

const CloudStorage = ({ data, triggerReload }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [path, setPath] = useState(searchParams.get("path") || "/home");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  if (!data || data === undefined) return null;
  const { files, folders } = data;

  if (!files && !folders) return null;

  if (path === "underfined") {
    setPath("/home");
  }
  const handlerOpenFile = (file, dir) => {
    const extension = file.split(".").pop();
    switch (extension) {
      case "pdf":
        navigate(`/cloud/pdf/${dir}/${file}`);
        break;
      default:
        console.log("default selected");
    }
  };

  return (
    <div className="flex flex-col h-full overflow-hidden py-4 text-gray-500">
      {loading && <Loading />}
      <div className="flex-shrink-0 mb-6">
        {error && <p className="text-red-500 my-2">{error}</p>}
        <div className="flex-shrink-0 mb-6">
          <h4 className="text-lg font-semibold mb-2 text-white">
            subfolders :
          </h4>{" "}
        </div>
        {folders.length === 0 ? (
          <p className="text-gray-500">No subfolders</p>
        ) : (
          <ul className="flex items-center gap-2 flex-wrap ">
            {folders.map((subfolder) => (
              <li
                key={subfolder.FolderStorageID}
                className="flex items-center justify-between bg-base hover:bg-base/60 px-2 py-2 w-56 rounded-md relative"
              >
                <button
                  type="button"
                  onClick={() =>
                    navigate(`/Cloud?path=${path}/${subfolder.Name}`)
                  }
                  className="flex gap-2 items-center w-full"
                >
                  <Folder className="mr-2 text-primary" />
                  <span className="flex gap-2 max-w-44 overflow-hidden">
                    <span className="truncate max-w-28">{subfolder.Name}</span>
                  </span>
                </button>
                <MenuCloud
                  path={path}
                  folder={subfolder}
                  setLoading={setLoading}
                  setError={setError}
                  navigate={navigate}
                  triggerReload={triggerReload}
                />
              </li>
            ))}
          </ul>
        )}
      </div>

      <div className="flex-grow overflow-auto h-full scrollbar">
        <h4 className="text-lg font-semibold mb-2 text-white">Files:</h4>
        {files.length === 0 ? (
          <p className="text-gray-500">No file</p>
        ) : (
          <ul className="flex items-start gap-4 flex-wrap content-start">
            {files.map((file) => (
              <li
                key={file.FileID}
                className="flex flex-col items-start justify-between cursor-pointer  bg-base px-2 py-2 w-56 rounded-md hover:bg-base/60 gap-2"
              >
                <div className="relative flex items-center justify-between w-full gap-2">
                  <div className="flex items-center gap-2">
                    <FileIcon type={file.ContentType} size={5} />
                    <span className="flex gap-2 max-w-44 overflow-hidden">
                      <span className="truncate max-w-28">{file.Name}</span>
                    </span>
                  </div>
                  <MenuCloud
                    path={path}
                    file={file}
                    setLoading={setLoading}
                    setError={setError}
                    navigate={navigate}
                    triggerReload={triggerReload}
                  />
                </div>
                <button
                  type="button"
                  onClick={() =>
                    handlerOpenFile(file.Name, file.FolderStorageID)
                  }
                  className="flex items-center justify-center w-full"
                >
                  <ConditionalImage
                    file={file}
                    folder={data.folder.Name || ""}
                  />
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default CloudStorage;
