import { useEffect, useState } from "react";
import { GlobalApi } from "../../global";
import LogoCenter from "../../components/logo_center";
import TemplatePage from "../template/template";
import { useNavigation } from "../../Redux/useNavigation";

const Api = () => {
  const { navigateTo } = useNavigation();
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState([]);
  const [showSecret, setShowSecret] = useState("");

  const getTeams = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${GlobalApi()}/api/list`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data.status === "error" && data.type === "jwt") {
        navigateTo("/login");
      }
      if (data.status === "ok") {
        setToken(data.apis);
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  const showData = (d) => {
    const date = new Date(d);
    return `${date.getDate()}:${date.getMonth()}:${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
  };

  useEffect(() => {
    getTeams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      const response = await fetch(`${GlobalApi()}/api/delete/${id}`, {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data.status === "error" && data.type === "jwt") {
        navigateTo("/login");
      }
      if (data.status === "ok") {
        getTeams();
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  return (
    <TemplatePage loading={loading} setLoading={setLoading}>
      <div
        className={`home_selection_models w-full min-h-[calc(100vh-160px)] py-4 flex ${token.length > 0 ? "items-start" : "items-center justify-center"}`}
      >
        <div
          className={`flex flex-col ${token.length > 0 ? "items-start" : "items-center"} gap-4 w-full`}
        >
          {token.length > 0 ? (
            <div className="w-full">
              <p className="text-gray-600 mt-10">Manage for api token</p>
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-primary_token/60 uppercase bg-base w-full">
                  <tr>
                    <th scope="col" className="px-3 w-1/4 py-3">
                      Name
                    </th>
                    <th scope="col" className="px-3 w-2/4 py-3">
                      key public
                    </th>
                    <th scope="col" className="px-3 w-1/4 py-3">
                      Last use
                    </th>
                    <th scope="col" className="px-3 w-1/4 py-3">
                      Delete
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {token &&
                    token.map((item) => (
                      <tr key={item.ID} className="bg-base/40 w-full">
                        <th
                          scope="row"
                          className="px-3 w-1/4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          {item.Name}
                        </th>
                        <th
                          scope="row"
                          className="px-3 w-2/4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          <input
                            type={showSecret === item.ID ? "text" : "password"}
                            defaultValue={item.Token}
                            className="w-full p-2 text-gray-900 bg-white border border-gray-300 rounded-lg dark:bg-gray-800 dark:text-white dark:border-gray-600"
                            readOnly
                          />
                        </th>
                        <th
                          scope="row"
                          className="px-3 w-1/4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          {showData(item.UpdatedAt)}
                        </th>
                        <td className="px-3 w-56 py-3 flex gap-2 items-center">
                          <button
                            type="button"
                            className="text-primary_token px-4 py-2 bg-primary/80 hover:bg-primary/95 rounded-md"
                            onClick={() =>
                              setShowSecret(
                                item.ID === showSecret ? "" : item.ID,
                              )
                            }
                          >
                            {showSecret === item.ID ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-eye-slash"
                                viewBox="0 0 16 16"
                              >
                                <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                                <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                                <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-eye"
                                viewBox="0 0 16 16"
                              >
                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                              </svg>
                            )}
                          </button>
                          <button
                            type="button"
                            onClick={() => handleDelete(item.ID)}
                            className="text-primary_token px-4 py-2 bg-primary/80 hover:bg-primary/95 rounded-md"
                          >
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill="currentColor"
                                d="M7 21q-.825 0-1.412-.587T5 19V6H4V4h5V3h6v1h5v2h-1v13q0 .825-.587 1.413T17 21zM17 6H7v13h10zM9 17h2V8H9zm4 0h2V8h-2zM7 6v13z"
                              />
                            </svg>
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <button
                onClick={() => navigateTo("/admin/api/new")}
                className=" mt-10 text-primary_token px-4 py-2 bg-primary/80 hover:bg-primary/95 rounded-md"
              >
                Create a Api token
              </button>
            </div>
          ) : (
            <>
              <LogoCenter />
              <p>Api token</p>
              <button
                onClick={() => navigateTo("/admin/api/new")}
                className="flex items-center justify-center px-4 py-2 text-lg font-semibold text-primary_token bg-base rounded-md shadow-md hover:bg-primary/30 focus:outline-none focus:ring-2 focus:ring-primary_token focus:ring-opacity-50 transition ease-in-out duration-150"
              >
                Create a token
              </button>
            </>
          )}
        </div>
      </div>
    </TemplatePage>
  );
};

export default Api;
