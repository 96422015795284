import React, { useState } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { vs2015 } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { CopyIcon, CheckIcon } from "lucide-react";

export const DocTitle = ({ children, post, update }) => {
  const date = new Date(update).toDateString();
  return (
    <header className="text-gray-300">
      <h1 className="h1 text-gray-300 font-semibold text-2xl">{children}</h1>
      <div className="py-6 text-sm">
        <span>
          {" "}
          Posted <time>{post}</time>{" "}
        </span>
        <span>
          {" "}
          Updated <time>{date || "not updated"}</time>
        </span>
        <div className="flex justify-end py-4 text-gray-300/40 w-full text-sm">
          {" "}
          <span>
            {" "}
            By{" "}
            <em>
              {" "}
              <span>Waxmaker</span>{" "}
            </em>{" "}
          </span>{" "}
        </div>
      </div>
    </header>
  );
};

export const Title = ({ children, ref_props, count }) => {
  if (ref_props === undefined) {
    return;
  }
  return (
    <h2
      ref={(el) => (ref_props.current[count] = el)}
      className="title text-gray-500 font-semibold text-lg"
    >
      {children}
    </h2>
  );
};

export const SubTitle = ({ children, ref_props, count }) => {
  if (ref_props === undefined) {
    return;
  }

  return (
    <h3
      ref={(el) => (ref_props.current[count] = el)}
      className="subtitle text-gray-500 font-semibold text-lg"
    >
      {children}
    </h3>
  );
};

export const Content = ({ children }) => {
  let hasPrefix = false;

  let contentPrefix = "";
  let contentBody = children;

  if (contentBody.length === 1 && contentBody[0].includes(":")) {
    hasPrefix = true;
  }

  if (hasPrefix) {
    const split = contentBody[0].split(":");
    if (split.length >= 2 && split[0].length <= 60 && split[1].length !== "") {
      contentPrefix = split[0];
      contentBody = split.slice(1).join(":").trim();
    } else {
      hasPrefix = false;
    }
  }

  return (
    <div
      className={`flex items-center text-white font-thin w-full bg-base/20 rounded-lg p-2 shadow-sm shadow-white/10`}
    >
      {hasPrefix && (
        <span className="text-primary p-1 bg-white/10 font-semibold rounded-md mr-2">
          {contentPrefix}:
        </span>
      )}
      <div className="flex-1">{contentBody}</div>
    </div>
  );
};

export const Ref = ({ href, children }) => {
  return (
    <React.Fragment>
      <a
        href={href}
        className="text-primary hover:text-primary/70 hover:underline font-normal px-2"
      >
        {children}
      </a>
    </React.Fragment>
  );
};

export const PromptInfo = ({ children }) => {
  return (
    <blockquote className="prompt-info flex items-center bg-base p-4 rounded-lg w-full text-white font-thin">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        className="fill-current text-primary mr-4"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="currentColor"
          d="M11 17h2v-6h-2zm1-8q.425 0 .713-.288T13 8t-.288-.712T12 7t-.712.288T11 8t.288.713T12 9m0 13q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22"
        />
      </svg>
      {children}
    </blockquote>
  );
};

export const Console = ({ children, type }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard
      .writeText(children)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
      })
      .catch((error) => {
        console.error("Failed to copy:", error);
      });
  };

  return (
    <div className="console bg-base p-4 rounded-lg w-full text-white font-thin">
      <div className="console-header flex items-center justify-between">
        <span className="console-icon flex gap-2">
          <div className="bg-gray-500/20 rounded-full h-3 w-3"> </div>
          <div className="bg-gray-500/20 rounded-full h-3 w-3"> </div>
          <div className="bg-gray-500/20 rounded-full h-3 w-3"> </div>
        </span>
        <span className="console-type text-gray-500/50 font-normal flex gap-1 items-center">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="currentColor"
              d="M4.825 12.025L8.7 15.9q.275.275.275.7t-.275.7t-.7.275t-.7-.275l-4.6-4.6q-.15-.15-.213-.325T2.426 12t.063-.375t.212-.325l4.6-4.6q.3-.3.713-.3t.712.3t.3.713t-.3.712zm14.35-.05L15.3 8.1q-.275-.275-.275-.7t.275-.7t.7-.275t.7.275l4.6 4.6q.15.15.213.325t.062.375t-.062.375t-.213.325l-4.6 4.6q-.3.3-.7.288t-.7-.313t-.3-.712t.3-.713z"
            />
          </svg>
          {type}
        </span>
        <button
          onClick={handleCopy}
          className={`${isCopied ? "text-green-500" : "text-gray-500/50"} hover:text-gray-500/70 focus:outline-none`}
          aria-label={isCopied ? "Copied!" : "Copy to clipboard"}
        >
          {isCopied ? <CheckIcon size={24} /> : <CopyIcon size={24} />}
        </button>
      </div>
      <SyntaxHighlighter
        showLineNumbers={true}
        className="bg-base text-white/80 overflow-x-scroll scrollbar"
        language={type}
        style={vs2015}
        id="code-content"
      >
        {children}
      </SyntaxHighlighter>
    </div>
  );
};
