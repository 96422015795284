import { useState } from "react";
import React from "react";
import { GlobalApi } from "../global";
import Loading from "../components/loading";
import Header from "../components/header";
import { useNavigation } from "../Redux/useNavigation";

const Form = () => {
  // handle form submission
  const { navigateTo } = useNavigation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [error_email, setError_email] = useState("");
  const [error_password, setError_password] = useState("");
  const [loading, setLoading] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [popup, setPopup] = useState(false);
  const [key, setKey] = useState("");

  const handlePasswordChange = (e) => {
    const pass = e.target.value;
    setPassword(pass);

    // Check password strength
    let strength = 0;
    if (pass.length >= 8) strength += 1;
    if (/[A-Z]/.test(pass)) strength += 1;
    if (/[a-z]/.test(pass)) strength += 1;
    if (/[0-9]/.test(pass)) strength += 1;
    if (/[@$!%*?&]/.test(pass)) strength += 1;

    setPasswordStrength(strength);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!email || !password) {
      setError("All fields are required");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError_email("Invalid email format");
      return;
    }
    if (passwordStrength < 3) {
      setError_password("Password is weak");
      return;
    }

    setLoading(true);
    try {
      const formData = new FormData();
      setLoading(true);
      formData.append("email", email);
      formData.append("password", password);
      const response = await fetch(`${GlobalApi()}/api/register`, {
        method: "POST",
        credentials: "include",
        body: formData,
      });
      const data = await response.json();
      if (data.error && data.type === "email") {
        setError_email(data.error);
        setLoading(false);
        return;
      } else if (data.error && data.type === "password") {
        setError_password(data.error);
        setLoading(false);
        return;
      } else if (data.error && data.type === "error") {
        setError(data.error);
        setLoading(false);
        return;
      }
      if (data.status === "ok" && data.secret_key) {
        setPopup(true);
        setKey(data.secret_key);
      }
    } catch (err) {
      setError("Login failed");
    } finally {
      setLoading(false);
    }
    setLoading(false);
  };

  const handleCopy = () => {
    const copy = document.getElementById("copy_api");
    navigator.clipboard
      .writeText(key)
      .then(() => {
        copy.textContent = "Copied";
      })
      .catch(() => {
        alert("Failed to copy!");
      });
    setTimeout(() => {
      copy.textContent = "Copy";
    }, 2000);
  };

  if (popup) {
    return (
      <div className="absolute w-screen h-screen flex items-center justify-center top-0 left-0 z-99">
        <div className="flex items-center justify-start min-h-screen bg-base">
          <div className="bg-chat  shadow-sm shadow-gray-700 rounded-md p-8 w-2/8 min-w-96 h-full flex gap-2 flex-col">
            <div className="flex items-center justify-between">
              <div className="font-brand text-3xl text-primary">Secret key</div>
              <button
                className="text-primary_token font-2xl"
                onClick={() => navigateTo("/login")}
              >
                X
              </button>
            </div>
            <p className="text-primary_token">
              Your new API key has been created. Copy it now, as we will not
              display it again.
            </p>
            <div className="flex gap-2 items-center justify-center w-full">
              <input
                autoCapitalize="none"
                autoCorrect="off"
                type="text"
                id="email"
                name="secret_key"
                value={key}
                className="w-full bg-gray-[#121C22] text-gray-400 h-12 p-1 border rounded-md border-primary bg-chat focus:ring focus:outline-none"
              />
              <button
                onClick={() => handleCopy()}
                className="bg-primary text-primary_token h-12 rounded-md px-4"
              >
                <p id="copy_api">Copy</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <form id="login-form" onSubmit={handleSubmit}>
      <div id="error" className="text-red-500 text-sm my-3">
        <p>{error}</p>
      </div>
      <div id="error_email" className="text-red-500 text-sm my-3">
        <p>{error_email}</p>
      </div>
      <div className="flex flex-col space-y-4 gap-4">
        <div className="w-full mb-4">
          <input
            autoCapitalize="none"
            autoCorrect="off"
            type="text"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="Email"
            autoComplete="email"
            autoFocus
            className="w-full bg-gray-[#121C22] text-gray-400 p-1 border rounded-md border-primary bg-chat focus:ring focus:outline-none"
          />
        </div>
      </div>
      <div id="error_password" className="text-red-500 text-sm my-3">
        <p>{error_password}</p>
      </div>
      <div className="mb-4">
        <input
          type="password"
          id="password"
          name="password"
          value={password}
          onChange={(e) => handlePasswordChange(e)}
          required
          autoComplete="current-password"
          placeholder="Password"
          className="w-full bg-gray-[#121C22] text-gray-400 p-1 border rounded-md border-primary bg-chat focus:ring focus:outline-none"
        />
      </div>
      <div className="w-full h-6 p-1 border-primary border mb-4 rounded-full">
        {passwordStrength === 0 && (
          <div className={`h-full w-0 rounded-full`} />
        )}
        {passwordStrength === 1 && (
          <div className={`h-full bg-red-400 w-1/5 rounded-full`} />
        )}
        {passwordStrength === 2 && (
          <div className={`h-full bg-red-400 w-2/5 rounded-full`} />
        )}
        {passwordStrength === 3 && (
          <div className={`h-full bg-red-400 w-3/5 rounded-full`} />
        )}
        {passwordStrength === 4 && (
          <div className={`h-full bg-blue-500 w-4/5 rounded-full`} />
        )}
        {passwordStrength >= 5 && (
          <div className={`h-full bg-green-500 w-full rounded-full`} />
        )}
      </div>
      <div className="mb-4">
        <button
          type="submit"
          className="flex items-center gap-2 justify-center w-full bg-primary text-gray-700 font-bold text-md py-2 px-4 rounded-md hover:bg-primary/75 focus:outline-none focus:bg-gray-400 ease-out duration-75"
        >
          register
          {loading && <Loading />}
        </button>
      </div>
      <div
        className="text-primary_token hover:underline hover:text-primary cursor-pointer underline-offset-1 ease-in-out duration-75 text-lg"
        onClick={() => navigateTo("/login")}
      >
        <p>Connect to your account</p>
      </div>
    </form>
  );
};

const Register = () => {
  return (
    <>
      <Header isMenu={false} />
      <div className="flex items-center justify-center min-h-screen bg-base">
        <div className="bg-chat  shadow-sm shadow-gray-700 rounded-md p-8 w-2/8 min-w-96 h-full">
          <div className="p-8 font-brand text-center text-3xl bg-gradient-to-r from-pink-400 via-red-400 to-orange-400 text-transparent bg-clip-text">
            Register
          </div>
          <Form />
        </div>
      </div>
    </>
  );
};

export default Register;
