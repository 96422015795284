import { useState } from "react";
import TemplatePage from "../template/template";

const Settings = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  setError("");
  return (
    <TemplatePage loading={loading} setLoading={setLoading}>
      {error && <p className="text-red-500 text-sm my-3">{error}</p>}
      <p>Manage Vault setting</p>
    </TemplatePage>
  );
};
export default Settings;
