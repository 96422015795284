import { useState } from "react";
import { GlobalApi } from "../../global";
import Button from "../../components/button";
import TemplatePage from "../template/template";
import { useNavigation } from "../../Redux/useNavigation";

const NewSecretCustom = () => {
  const { navigateTo } = useNavigation();
  const [loading, setLoading] = useState(false);
  const [secretName, setSecretName] = useState("");
  const [error, setError] = useState("");
  const [publicKey, setPublicKey] = useState("");
  const [privateKey, setPrivateKey] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!secretName && secretName.length > 30) {
      setError("All fields are required");
    }
    setLoading(true);
    setError("");
    try {
      const formData = new FormData();
      formData.append("name", secretName);
      formData.append("publicKey", publicKey);
      formData.append("privateKey", privateKey);
      const response = await fetch(
        `${GlobalApi()}/api/user/new_secret_custom`,
        {
          method: "POST",
          credentials: "include",
          body: formData,
        },
      );
      const data = await response.json();
      if (data.status === "error") {
        if (data.type === "jwt") {
          navigateTo("/login");
        }
        setError(data.error);
      } else {
        navigateTo("/admin/secrets");
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  return (
    <TemplatePage loading={loading} setLoading={setLoading}>
      <div className="home_selection_models w-full min-h-[calc(100vh-160px)] py-4 flex gap-4">
        <form onSubmit={handleSubmit} className="w-1/2 flex flex-col gap-2">
          <h1 className="text-primary_token text-2xl py-4">New secret sops</h1>
          <p className="text-gray-600">Create a new secret key.</p>
          {error && <p className="text-red-600">{error}</p>}
          <p className="text-primary_token pt-2 pb-1">Name</p>
          <input
            autoCapitalize="none"
            autoCorrect="off"
            type="text"
            id="name"
            name="name"
            onChange={(e) => setSecretName(e.target.value)}
            value={secretName}
            className="w-full bg-gray-[#121C22] text-gray-400 h-12 p-1 border rounded-md border-primary bg-chat focus:ring focus:outline-none"
          />
          <p className="text-primary_token pt-2 pb-1">Public key</p>
          <input
            autoCapitalize="none"
            autoCorrect="off"
            type="text"
            id="publicKey"
            name="publicKey"
            onChange={(e) => setPublicKey(e.target.value)}
            value={publicKey}
            className="w-full bg-gray-[#121C22] text-gray-400 h-12 p-1 border rounded-md border-primary bg-chat focus:ring focus:outline-none"
          />
          <p className="text-primary_token pt-2 pb-1">Public key</p>
          <input
            autoCapitalize="none"
            autoCorrect="off"
            type="text"
            id="privateKey"
            name="privateKey"
            onChange={(e) => setPrivateKey(e.target.value)}
            value={privateKey}
            className="w-full bg-gray-[#121C22] text-gray-400 h-12 p-1 border rounded-md border-primary bg-chat focus:ring focus:outline-none"
          />
          <Button>Create</Button>
        </form>
      </div>
    </TemplatePage>
  );
};

export default NewSecretCustom;
