import SideBarMenu from "./adminMenu";
import { AdminItemMenu, VaulsItemMenu } from "../data/item";
import { AdminSVG, CloudSVG, DocsSVG, TwoFASVG, VaultSVG } from "./svg";
import { useNavigation } from "../Redux/useNavigation";

const SideBar = () => {
  const { navigateTo } = useNavigation();
  return (
    <div className="text-primary_token flex-shrink-0 overflow-x-hidden bg-base w-64 h-full min-h-[calc(100vh-64px)]">
      <div className="h-full w-64 p-2">
        <div className="flex h-full min-h-0 flex-col">
          <div className="flex flex-col items-center border-primary_token">
            <nav className="w-full p-2 flex-1 gap-2">
              <div id="sideBar_cloud" className="flex flex-col">
                <button
                  type="button"
                  onClick={() => navigateTo("/Cloud?path=/home")}
                  className={`flex items-center justify-between text-primary_token "bg-chat"hover:bg-chat font-medium rounded-lg text-sm px-5 py-2.5 text-center`}
                >
                  <div className="flex items-center gap-2">
                    <CloudSVG />
                    Cloud
                  </div>
                </button>
              </div>
            </nav>
            <nav className="w-full p-2 flex-1 gap-2">
              <div id="sideBar-two_fa" className="flex flex-col">
                <button
                  type="button"
                  onClick={() => navigateTo("/twoFa")}
                  className={`flex items-center justify-between text-primary_token "bg-chat"hover:bg-chat font-medium rounded-lg text-sm px-5 py-2.5 text-center`}
                >
                  <div className="flex items-center gap-2">
                    <TwoFASVG />
                    Two_FA
                  </div>
                </button>
              </div>
            </nav>
            <nav className="w-full p-2 flex-1 gap-2">
              <div id="sideBar-docs" className="flex flex-col">
                <button
                  type="button"
                  onClick={() => navigateTo("/docs")}
                  className={`flex items-center justify-between text-primary_token "bg-chat"hover:bg-chat font-medium rounded-lg text-sm px-5 py-2.5 text-center`}
                >
                  <div className="flex items-center gap-2">
                    <DocsSVG />
                    Docs
                  </div>
                </button>
              </div>
            </nav>
            <SideBarMenu item={VaulsItemMenu} title="Vaults" SVG={VaultSVG} />
            <SideBarMenu item={AdminItemMenu} title="Admin" SVG={AdminSVG} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
