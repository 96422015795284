import { useEffect, useState } from "react";
import Header from "../components/header";
import { GlobalApi } from "../global";
import Loading from "../components/loading";
import SideBar from "../components/sidebar";
import BreadCrumb from "../components/breadcrumb";
import { useNavigation } from "../Redux/useNavigation";

const Home = () => {
  const { navigateTo } = useNavigation();
  const [loading, setLoading] = useState(false);

  const checkCredentials = async () => {
    try {
      setLoading(true);
      const resp = await fetch(`${GlobalApi()}/api/login/credential`, {
        method: "POST",
        credentials: "include",
      });
      const data = await resp.json();
      if (data.status === "error") {
        navigateTo("/login");
      }
    } catch (_) {
      navigateTo("/login");
    }
    setLoading(false);
  };
  useEffect(() => {
    checkCredentials();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Header isMenu={true} />
      <div className="relative z-0 flex min-h-[calc(100vh-64px)] w-full overflow-hidden bg-chat">
        {loading && <Loading />}
        <SideBar />
        <div className="flex flex-col w-full min-h-[calc(100vh-64px)] p-8">
          <BreadCrumb />
          <div className="home_selection_models w-full min-h-[calc(100vh-160px)] py-4 flex items-center justify-center">
            <div className="flex flex-col items-center gap-2">
              <div className="relative w-14 h-14 rounded-full">
                <div className="absolute rounded-full inset-0 w-full h-full bg-gradient-to-r from-pink-400 via-red-400 to-orange-400 "></div>
                <img
                  className="absolute inset-0 w-full h-full object-cover"
                  src="/lock.svg"
                  alt="lock"
                />
                <span>
                  <img
                    className={`absolute ${false ? "rotate-90" : ""} duration-100 ease-in-out object-cover h-1/3 w-auto left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2`}
                    src="/keyhole.png"
                    alt="keyhole"
                  />
                </span>
              </div>
              <h1 className="text-3xl text-primary_token">
                Welcome to your vault
              </h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
