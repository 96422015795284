import { useEffect, useState } from "react";
import TemplatePage from "../template/template";
import { GlobalApi } from "../../global";
import Button from "../../components/button";
import Dropdown from "../../components/dropdown";
import { decode } from "../../cryptho/encryption";
import { useNavigation } from "../../Redux/useNavigation";

const Create_vault = () => {
  const { navigateTo } = useNavigation();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [folder, setFolder] = useState("folder");
  const [content, setContent] = useState("");
  const [type, setType] = useState("type");
  const [key, setKey] = useState("key");
  const [error, setError] = useState("");
  const [folderList, setFolderList] = useState([]);
  const [keys, setKeys] = useState([]);

  useEffect(() => {
    getKeys();
    getFolders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getKeys = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${GlobalApi()}/api/user`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data.status === "error" && data.type === "jwt") {
        navigateTo("/login");
      }
      if (data.status === "error") {
        setError(data.error);
      }
      if (data.status === "ok") {
        const dec = await decode(data.secrets);
        setKeys([]);
        setKeys((prevKeys) => [...prevKeys, ...dec.map((item) => item.Name)]);
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  const getFolders = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${GlobalApi()}/api/user/folder`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data.status === "error" && data.type === "jwt") {
        navigateTo("/login");
      }
      if (data.status === "error") {
        setError(data.error);
      }
      if (data.status === "ok") {
        const decode_folder = await decode(data.folders);
        setFolderList([]);
        setFolderList((prevKeys) => [
          ...prevKeys,
          ...decode_folder.map((item) => item.FolderName),
        ]);
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (key === "key" || folder === "folder" || type === "type") {
      setError("All fields are required");
      return;
    }
    if (!name && name.length > 30) {
      setError("All fields are required");
      return;
    }
    setLoading(true);
    setError("");
    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("description", description);
      formData.append("folder", folder);
      formData.append("content", content);
      formData.append("type", type);
      formData.append("key", key);
      const response = await fetch(`${GlobalApi()}/api/vault/new`, {
        method: "POST",
        credentials: "include",
        body: formData,
      });
      const data = await response.json();
      if (data.status === "error") {
        if (data.type === "jwt") {
          navigateTo("/login");
        }
        setError(data.error);
      } else {
        navigateTo("/vaults");
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  return (
    <TemplatePage loading={loading} setLoading={setLoading}>
      <div className="home_selection_models w-full min-h-[calc(100vh-160px)] py-4 flex gap-4 overflow-y-auto scrollbar">
        <form onSubmit={handleSubmit} className="w-full">
          <h1 className="text-primary_token text-2xl py-4">New env</h1>
          <p className="text-gray-600">Create a new env.</p>
          {error && <p className="text-red-600">{error}</p>}
          <div className="w-full grid grid-cols-3 gap-4">
            <Dropdown
              title={"key"}
              selected={key}
              setSelected={setKey}
              list={keys}
            />
            <Dropdown
              title={"folder"}
              selected={folder}
              setSelected={setFolder}
              list={folderList}
            />
            <Dropdown
              title={"type"}
              selected={type}
              setSelected={setType}
              list={["env", "kube"]}
            />
          </div>
          <div className="w-full flex flex-col gap-4 mt-4">
            <input
              autoCapitalize="none"
              autoCorrect="off"
              type="text"
              id="name"
              name="name"
              placeholder="name"
              onChange={(e) => setName(e.target.value)}
              value={name}
              className="w-full bg-gray-[#121C22] text-gray-400 h-8 p-1 border rounded-md border-primary bg-chat focus:ring focus:outline-none"
            />
            <textarea
              type="text"
              id="content"
              name="content"
              rows={20}
              placeholder={
                type === "type" || "env" ? "key=value" : "secret.yaml"
              }
              onChange={(e) => setContent(e.target.value)}
              value={content}
              className="w-full bg-gray-[#121C22] text-gray-400 min-h-28 p-1 border rounded-md border-primary bg-chat focus:ring focus:outline-none"
            />
            <textarea
              autoCapitalize="none"
              autoCorrect="off"
              type="text"
              placeholder="description"
              id="description"
              name="description"
              onChange={(e) => setDescription(e.target.value)}
              value={description}
              className="w-full bg-gray-[#121C22] text-gray-400 min-h-10 p-1 border rounded-md border-primary bg-chat focus:ring focus:outline-none"
            />
          </div>
          <Button>Create</Button>
        </form>
      </div>
    </TemplatePage>
  );
};

export default Create_vault;
