import { useEffect, useRef, useState } from "react";
import { MoreVertical, CircleArrowDown, Trash2, PenLine } from "lucide-react";
import { GlobalApi } from "../global";
import PopupDeleteCloud from "./popupDeleteCloud";
import PopupRename from "./popupRename";
import { useNavigation } from "../Redux/useNavigation";

const MenuCloud = ({
  folder,
  path,
  setLoading,
  setError,
  file,
  triggerReload,
}) => {
  const { navigateTo } = useNavigation();
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const [popup, setPopup] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [popupRename, setPopupRename] = useState(false);

  const toogleMenu = () => setIsOpen(!isOpen);

  useEffect(() => {
    const handlerClickOutside = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handlerClickOutside);
    return () => document.removeEventListener("mousedown", handlerClickOutside);
  }, []);

  const handlerDownload = async () => {
    setLoading(true);
    setError("");
    try {
      const response = await fetch(
        `${GlobalApi()}/api/cloud/download/file/${file.FileID}`,
        {
          method: "POST",
          credentials: "include",
        },
      );
      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.status && errorData.status === "error") {
          if (errorData.type === "jwt") {
            navigateTo("/login");
          }
          setError(errorData.error);
          setLoading(false);
          return;
        }
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = file.Name;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };

  const handlerDeleteFolder = async (folder) => {
    setLoading(true);
    setError("");
    try {
      const formData = new FormData();
      formData.append("path", path);
      const response = await fetch(
        `${GlobalApi()}/api/cloud/delete/folder/${folder}`,
        {
          method: "POST",
          credentials: "include",
          body: formData,
        },
      );
      const data = await response.json();
      if (data.status === "error") {
        if (data.type === "jwt") {
          navigateTo("/login");
        }
        setError(data.error);
      } else if (data.error) {
        setError(data.error);
      } else if (data.status === "ok") {
        triggerReload();
      }
    } catch (e) {
      setError(e);
    }
    setIsOpen(false);
    setLoading(false);
  };

  const handlerRenameRequest = async (Rename, FileID, state) => {
    setLoading(true);
    setError("");
    const formData = new FormData();
    formData.append("name", Rename);
    try {
      const response = await fetch(
        `${GlobalApi()}/api/cloud/rename/${state}/${FileID}`,
        {
          method: "POST",
          credentials: "include",
          body: formData,
        },
      );
      const data = await response.json();
      if (data.status === "error") {
        if (data.type === "jwt") {
          navigateTo("/login");
        }
        setError(data.error);
      } else if (data.error) {
        setError(data.error);
      } else if (data.status === "ok") {
        triggerReload();
      }
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };

  const handlerDeleteFile = async (filename) => {
    setLoading(true);
    setError("");
    const formData = new FormData();
    formData.append("path", path);
    try {
      const response = await fetch(
        `${GlobalApi()}/api/cloud/delete/file/${filename}`,
        {
          method: "POST",
          credentials: "include",
          body: formData,
        },
      );
      const data = await response.json();
      if (data.status === "error") {
        if (data.type === "jwt") {
          navigateTo("/login");
        }
        setError(data.error);
      } else if (data.error) {
        setError(data.error);
      } else if (data.status === "ok") {
        triggerReload();
      }
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };

  const handlerRename = () => {
    setIsOpen(false);
    setPopupRename(true);
  };
  return (
    <>
      {popup && (
        <PopupDeleteCloud
          setPopup={setPopup}
          id={file ? file.Name : folder.Name}
          handleDelete={file ? handlerDeleteFile : handlerDeleteFolder}
          text={`Are you sure you want to delete this team ${deleteId}`}
        />
      )}
      {popupRename && (
        <PopupRename
          setPopup={setPopupRename}
          fileName={file ? file.Name : folder.Name}
          ID={file ? file.FileID : folder.FolderStorageID}
          state={file ? "file" : "folder"}
          handleRequest={handlerRenameRequest}
        />
      )}

      <div ref={menuRef} className="relative w-full flex justify-end">
        {" "}
        <button
          type="button"
          onClick={toogleMenu}
          className="relative text-primary flex flex-col justify-center z-10 bg-base position-static p-2 hover:bg-gray-700 rounded-md"
        >
          <MoreVertical />
        </button>
        {isOpen && (
          <div className="absolute flex flex-col top-full right-0 gap-2 rounded-md p-2 shadow-md bg-base shadow-white/10 z-50">
            <button
              type="button"
              className="text-primary flex gap-2 p-2 hover:bg-gray-600 rounded-md"
              onClick={() => handlerRename()}
            >
              <PenLine />
              Rename
            </button>

            {file && (
              <button
                type="button"
                className="text-primary flex gap-2 p-2 hover:bg-gray-600 rounded-md"
                onClick={() => handlerDownload()}
              >
                <CircleArrowDown />
                Download
              </button>
            )}

            <button
              type="button"
              className="text-primary flex gap-2 p-2 hover:bg-gray-600 rounded-md"
              onClick={() =>
                setPopup(true) && setDeleteId(file ? file.Name : folder.Name)
              }
            >
              <Trash2 />
              Delete
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default MenuCloud;
