import { useEffect, useState } from "react";
import { GlobalApi } from "../../global";
import { Terminal } from "lucide-react";
import TemplatePage from "../template/template";
import { Eraser } from "lucide-react";
import { useNavigation } from "../../Redux/useNavigation";
import Pagination from "../../components/paggination";
import Loading from "../../components/loading";
import Select from "react-select";

const Tag = [" ", "info", "warning", "error", "success"];

const Log = () => {
  const { navigateTo } = useNavigation();
  const [loading, setLoading] = useState(false);
  const [logs, setLogs] = useState([]);
  const [checkedID, setCheckedID] = useState([]);
  const [all, setAll] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(100);
  const [isLoaded, setIsLoaded] = useState(false);
  const [tag, setTag] = useState("");

  const opts = Tag.map((tag) => ({ value: tag, label: tag }));

  useEffect(() => {
    const fetchLogs = async () => {
      setIsLoaded(true);
      try {
        const response = await fetch(
          `${GlobalApi()}/api/log?page=${currentPage}&limit=${itemsPerPage}&tag=${tag}`,
          {
            method: "GET",
            credentials: "include",
          },
        );
        const newLogs = await response.json();
        if (newLogs.status === "error" && newLogs.type === "jwt") {
          navigateTo("/login");
        } else if (newLogs.status === "ok") {
          setLogs(newLogs.logs);
          setTotalPages(newLogs.totalPages);
        }
      } catch (error) {
        console.error("Error fetching logs:", error);
      }
      setIsLoaded(false);
    };
    fetchLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, itemsPerPage, tag]);

  const handleDelete = async () => {
    if (checkedID.length === 0) return;
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("id", checkedID);
      const response = await fetch(`${GlobalApi()}/api/log/delete`, {
        method: "DELETE",
        credentials: "include",
        body: formData,
      });
      const data = await response.json();
      if (data.status === "error" && data.type === "jwt") {
        navigateTo("/login");
      } else if (data.status === "ok") {
        setLogs(logs.filter((log) => !checkedID.includes(log.LogID)));
        setCheckedID([]);
        setAll(false);
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  const handlerCheck = (id) => {
    setCheckedID((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id],
    );
  };

  const handleSelectAll = () => {
    setAll(!all);
    setCheckedID(all ? [] : logs.map((log) => log.LogID));
  };

  return (
    <TemplatePage loading={loading} setLoading={setLoading}>
      <div className="home_selection_models w-full min-h-[calc(100vh-160px)] py-4 flex flex-col items-center justify-center bg-black/60 rounded-lg p-4">
        <div className="flex gap-2 items-center justify-between w-full text-gray-300">
          <input type="checkbox" checked={all} onChange={handleSelectAll} />
          <div className="flex item-center gap-2 min-w-52">
            <Select
              options={opts}
              value={{ value: tag, label: tag }}
              onChange={(e) => setTag(e.value)}
              menuPlacement="auto"
              className="bg-primary text-white mr-4 rounded-md w-full"
              styles={{
                control: (base) => ({
                  ...base,
                  backgroundColor: "#121C22",
                  borderColor: "#FF865B",
                }),
                menu: (base) => ({
                  ...base,
                  backgroundColor: "#121C22",
                  color: "#fff",
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isFocused ? "#FF865B" : "#121C22",
                  color: state.isSelected ? "#FF865B" : "#fff",
                  ":hover": {
                    backgroundColor: "#FF865B",
                    color: "#fff",
                  },
                }),
                singleValue: (base) => ({
                  ...base,
                  color: "#FF865B",
                }),
                placeholder: (base) => ({
                  ...base,
                  color: "#FF865B",
                }),
              }}
            />
            <button
              type="button"
              onClick={handleDelete}
              disabled={checkedID.length === 0}
              className="pr-4 hover:text-primary cursor-pointer"
            >
              <Eraser />
            </button>
          </div>
        </div>
        <div className="h-[1px] my-2 bg-gray-300/40 rounded-full w-full"></div>
        <div className="flex flex-col h-[calc(100vh-160px)] w-full rounded-lg overflow-auto scrollbar text-gray-300 scroll-smooth">
          {isLoaded && <Loading />}
          {!isLoaded &&
            logs.map((log, index) => (
              <div key={index} className="flex gap-2 text-sm items-center">
                <input
                  type="checkbox"
                  onChange={() => handlerCheck(log.LogID)}
                  checked={checkedID.includes(log.LogID)}
                  className="bg-base hover:cursor-pointer"
                />
                <Terminal />
                <p>{log.LogID}</p>
                <p>{log.IP}</p>
                <p>{log.Level}</p>
                <p>{log.Category}</p>
                <p>{log.Description}</p>
              </div>
            ))}
        </div>
        <Pagination
          currentPage={currentPage}
          onPageChange={setCurrentPage}
          setItemsPerPage={setItemsPerPage}
          totalPages={totalPages}
          itemsPerPage={itemsPerPage}
        />
      </div>
    </TemplatePage>
  );
};

export default Log;
