import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPath: "/",
  previousPath: null,
};

const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    navigate: (state, action) => {
      state.previousPath = state.currentPath;
      state.currentPath = action.payload;
    },
    goBack: (state) => {
      if (state.previousPath) {
        const temp = state.currentPath;
        state.currentPath = state.previousPath;
        state.previousPath = temp;
      }
    },
  },
});

export const { navigate, goBack } = navigationSlice.actions;
export default navigationSlice.reducer;
