import { useRef, useState } from "react";

const PopupRename = ({ setPopup, fileName, handleRequest, ID, state }) => {
  // trim extension from filename
  const trimExtension = (fileName) => {
    const extension = fileName.split(".").pop();
    return fileName.slice(0, -extension.length - 1);
  };

  const [newName, setNewName] = useState(
    state === "file" ? trimExtension(fileName) : fileName,
  );
  const extension = fileName.split(".").pop();
  const inputRef = useRef(null);

  const handleFocus = () => {
    const nameLength = newName.length;
    if (inputRef.current) {
      inputRef.current.setSelectionRange(0, nameLength);
    }
  };

  const onChange = (e) => {
    if (state === "file") {
      const value = e.target.value;
      const baseName = value.substring(0, value.lastIndexOf("."));
      setNewName(baseName);
    } else {
      setNewName(e.target.value);
    }
  };

  return (
    <div
      id="popup-delete"
      className="fixed top-0 left-0 z-50 flex justify-center items-center h-screen w-screen bg-transparent"
    >
      <div className="relative p-4 w-full max-w-md max-h-full bg-base shadow-white/10 shadow-sm rounded-lg">
        <div className="relative rounded-lg shadow bg-base">
          <button
            type="button"
            onClick={() => setPopup(false)}
            className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
          >
            <svg
              className="w-3 h-3"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <form
            onSubmit={() =>
              handleRequest(
                state === "file" ? newName + "." + extension : newName,
                ID,
                state,
              )
            }
            className="p-4 md:p-5 text-start flex flex-col gap-2"
          >
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Rename
            </h3>
            <input
              ref={inputRef}
              value={state === "file" ? `${newName}.${extension}` : newName}
              onChange={(e) => {
                onChange(e);
              }}
              onFocus={handleFocus}
              className="input input-bordered w-full bg-gray-600 rounded-lg p-2 text-black"
            />
            <span>
              <button
                data-modal-hide="popup-modal"
                type="button"
                onClick={() => setPopup(false)}
                className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center"
              >
                Cancel
              </button>
              <button
                data-modal-hide="popup-delete"
                type="submit"
                className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              >
                Ok
              </button>
            </span>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PopupRename;
