import { useState } from "react";
import PopupDelete from "../components/popupDelete";
import { GlobalApi } from "../global";
import { useNavigation } from "../Redux/useNavigation";

const TableTeams = ({ item, setItem }) => {
  const { navigateTo } = useNavigation();
  const [popup, setPopup] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [error, setError] = useState("");

  const handleDelete = (id) => {
    setPopup(true);
    setDeleteId(id);
  };

  const handleApprouver = async () => {
    setPopup(false);
    try {
      const response = await fetch(
        `${GlobalApi()}/api/teams/delete/${deleteId}`,
        {
          method: "DELETE",
          credentials: "include",
        },
      );
      const data = await response.json();
      if (data.status === "error") {
        setError(data.error);
      }
      if (data.status === "ok") {
        setItem(item.filter((item) => item.TeamID !== deleteId));
      }
    } catch (e) {
      setError(e);
    }
  };

  return (
    <div className="relative overflow-x-auto mt-2">
      {popup && (
        <PopupDelete
          setPopup={setPopup}
          id={deleteId}
          handleDelete={handleApprouver}
          text={`Are you sure you want to delete this team ${deleteId}`}
        />
      )}
      {error && <p className="text-red-500 text-sm my-3">{error}</p>}
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-primary_token/60 uppercase bg-base">
          <tr>
            <th scope="col" className="px-3 w-56 py-3">
              Teams name
            </th>
            <th scope="col" className="px-3 w-56 py-3">
              Members
            </th>
            <th scope="col" className="px-3 w-56 py-3">
              Delete
            </th>
          </tr>
        </thead>
        <tbody>
          {item &&
            item.map((item) => (
              <tr key={item.TeamID} className="bg-base/40">
                <th
                  scope="row"
                  className="px-3 w-56 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {item.TeamName}
                </th>
                <th
                  scope="row"
                  className="px-3 w-56 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {item.TeamMembers.length}
                </th>
                <td className="px-3 w-56 py-3 flex gap-2 items-center">
                  <button
                    type="button"
                    className="text-primary_token px-4 py-2 bg-primary/80 hover:bg-primary/95 rounded-md"
                    onClick={() =>
                      navigateTo("/admin/teams/edit/" + item.TeamID)
                    }
                  >
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                      >
                        <path d="M7 7H6a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2-2v-1" />
                        <path d="M20.385 6.585a2.1 2.1 0 0 0-2.97-2.97L9 12v3h3zM16 5l3 3" />
                      </g>
                    </svg>
                  </button>
                  <button
                    type="button"
                    onClick={() => handleDelete(item.TeamID)}
                    className="text-primary_token px-4 py-2 bg-primary/80 hover:bg-primary/95 rounded-md"
                  >
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill="currentColor"
                        d="M7 21q-.825 0-1.412-.587T5 19V6H4V4h5V3h6v1h5v2h-1v13q0 .825-.587 1.413T17 21zM17 6H7v13h10zM9 17h2V8H9zm4 0h2V8h-2zM7 6v13z"
                      />
                    </svg>
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export { TableTeams };
