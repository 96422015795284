import { useNavigation } from "../../Redux/useNavigation";
import BreadCrumb from "../../components/breadcrumb";
import Header from "../../components/header";
import Loading from "../../components/loading";
import SideBar from "../../components/sidebar";
import { GlobalApi } from "../../global";
import { useEffect } from "react";

const TemplatePage = ({ children, loading, setLoading }) => {
  const { navigateTo } = useNavigation();
  const checkCredentials = async () => {
    try {
      setLoading(true);
      const resp = await fetch(`${GlobalApi()}/api/login/credential`, {
        method: "POST",
        credentials: "include",
      });
      const data = await resp.json();
      if (data.status === "error") {
        navigateTo("/login");
      }
    } catch (_) {
      navigateTo("/login");
    }
    setLoading(false);
  };
  useEffect(() => {
    checkCredentials();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Header isMenu={true} />
      <div className="relative z-0 flex h-[calc(100vh-66px)] max-w-screen overflow-hidden bg-chat">
        <SideBar />
        <div className="flex  flex-1 flex-col w-[100px] min-h-[calc(100vh-64px)] p-1 md:p-6">
          {loading && <Loading />}
          {!loading && (
            <div className="w-full h-full flex flex-col">
              <BreadCrumb />
              {children}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TemplatePage;
