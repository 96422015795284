import Select from "react-select";
import React from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";

const itemList = [5, 10, 15, 20, 25, 50, 100];

const Pagination = ({
  onPageChange,
  setItemsPerPage,
  currentPage,
  totalPages,
  itemsPerPage,
}) => {
  const options = itemList.map((item) => ({
    value: item,
    label: item,
  }));
  return (
    <React.Fragment>
      <div className="fixed left-0 bottom-8 w-full flex justify-center items-center mt-4">
        <button
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="p-2 bg-primary text-white rounded-md mr-2 disabled:opacity-50"
        >
          <ChevronLeft size={20} />
        </button>
        <span className="mx-4 text-gray-300">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="p-2 bg-primary text-white rounded-md ml-2 disabled:opacity-50"
        >
          <ChevronRight size={20} />
        </button>
      </div>
      <Select
        options={options}
        value={{ value: itemsPerPage, label: itemsPerPage }}
        onChange={(selectedOption) => setItemsPerPage(selectedOption.value)}
        menuPlacement="top"
        className="bg-primary text-white w-24 ml-4 rounded-md fixed right-14 bottom-8"
        styles={{
          control: (base) => ({
            ...base,
            backgroundColor: "#121C22",
            borderColor: "#FF865B",
          }),
          menu: (base) => ({
            ...base,
            backgroundColor: "#121C22",
            color: "#fff",
          }),
          option: (base, state) => ({
            ...base,
            backgroundColor: state.isFocused ? "#FF865B" : "#121C22",
            color: state.isSelected ? "#FF865B" : "#fff",
            ":hover": {
              backgroundColor: "#FF865B",
              color: "#fff",
            },
          }),
          singleValue: (base) => ({
            ...base,
            color: "#FF865B",
          }),
          placeholder: (base) => ({
            ...base,
            color: "#FF865B",
          }),
        }}
      />
    </React.Fragment>
  );
};

export default Pagination;
