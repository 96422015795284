import { useEffect, useState } from "react";
import { GlobalApi } from "../../global";
import LogoCenter from "../../components/logo_center";
import { TableTeams } from "../../utils/table";
import TemplatePage from "../template/template";
import { useNavigation } from "../../Redux/useNavigation";

const Teams_admin = () => {
  const { navigateTo } = useNavigation();
  const [loading, setLoading] = useState(false);
  const [teams, setTeams] = useState([]);

  const getTeams = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${GlobalApi()}/api/teams`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data.status === "error" && data.type === "jwt") {
        navigateTo("/login");
      }
      if (data.status === "ok") {
        setTeams(data.teams);
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    getTeams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TemplatePage loading={loading} setLoading={setLoading}>
      <div
        className={`home_selection_models w-full min-h-[calc(100vh-160px)] py-4 flex ${teams.length > 0 ? "items-start" : "items-center justify-center"}`}
      >
        <div
          className={`flex flex-col ${teams.length > 0 ? "items-start" : "items-center"} gap-4`}
        >
          {teams.length > 0 ? (
            <div>
              <p className="text-gray-600 mt-10">Manage members and settings</p>
              <TableTeams item={teams} setItem={setTeams} />
              <button
                onClick={() => navigateTo("/admin/teams/new")}
                className=" mt-10 text-primary_token px-4 py-2 bg-primary/80 hover:bg-primary/95 rounded-md"
              >
                Create a team
              </button>
            </div>
          ) : (
            <>
              <LogoCenter />
              <p>No teams</p>
              <button
                onClick={() => navigateTo("/admin/teams/new")}
                className="flex items-center justify-center px-4 py-2 text-lg font-semibold text-primary_token bg-base rounded-md shadow-md hover:bg-primary/30 focus:outline-none focus:ring-2 focus:ring-primary_token focus:ring-opacity-50 transition ease-in-out duration-150"
              >
                Create a team
              </button>
            </>
          )}
        </div>
      </div>
    </TemplatePage>
  );
};

export default Teams_admin;
