import React from "react";
import { useLocation, Link } from "react-router-dom";

const BreadCrumbPath = () => {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const path = searchParams.get("path") || "/";

  const pathnames = path
    .split("/")
    .filter((x) => x)
    .filter((x) => !/^\d+$/.test(x));

  if (path === "/home") {
    return null;
  }

  return (
    <nav
      className="flex my-1 text-sm px-5 py-3 text-gray-700"
      aria-label="Breadcrumb"
    >
      <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
        <li className="inline-flex items-center">
          <Link
            to={{ search: `?path=/` }}
            className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
          ></Link>
        </li>
        {pathnames.map((value, index) => {
          const last = index === pathnames.length - 1;
          const to = `/${pathnames.slice(0, index + 1).join("/")}`;
          return (
            <li key={to} className={last ? "text-gray-500" : ""}>
              <div className="flex items-center">
                <p className="text-gray-500 font-bold">/</p>
                {!last ? (
                  <Link
                    to={{ search: `?path=${to}` }}
                    className="ms-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white"
                  >
                    {value.charAt(0).toUpperCase() + value.slice(1)}
                  </Link>
                ) : (
                  <span className="ms-1 text-sm font-medium text-gray-500 md:ms-2">
                    {value.charAt(0).toUpperCase() + value.slice(1)}
                  </span>
                )}
              </div>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default BreadCrumbPath;
