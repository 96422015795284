import { motion } from "framer-motion";

const KeyAnimation = ({ isVisible, KeyAnimation }) => {
  return (
    <>
      <motion.div
        animate={{ opacity: isVisible ? 1 : 0, scale: isVisible ? 1 : 0 }}
        transition={{ ease: "easeOut", duration: 1 }}
        exit={{ opacity: 0, scale: 0 }}
        className="absolute flex items-center justify-center"
      >
        <div className="relative w-48 h-48 rounded-full">
          <div className="absolute rounded-full inset-0 w-full h-full bg-gradient-to-r from-pink-400 via-red-400 to-orange-400 "></div>
          <img
            className="absolute inset-0 w-full h-full object-cover"
            src="/lock.svg"
            alt="lock"
          />
          <span>
            <img
              className={`absolute ${KeyAnimation ? "rotate-90" : ""} duration-100 ease-in-out object-cover h-1/3 w-auto left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2`}
              src="/keyhole.png"
              alt="keyhole"
            />
          </span>
        </div>
      </motion.div>
    </>
  );
};

export default KeyAnimation;
