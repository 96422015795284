import { useLocation } from "react-router-dom";
import { useNavigation } from "../Redux/useNavigation";

const BreadCrumb = () => {
  const location = useLocation();
  const { navigateTo } = useNavigation();
  // need add regex to remove numbers
  const re = new RegExp("^[0-9]*$");
  // filter pathname with regex
  const pathnames = location.pathname
    .split("/")
    .filter((x) => x)
    .filter((x) => re.test(x) === false);

  if (location.pathname === "/") {
    return null;
  }
  return (
    <nav
      className="flex my-1 px-5 py-3 text-gray-700 border border-cyan-200 rounded-lg dark:border-gray-700"
      aria-label="Breadcrumb"
    >
      <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
        <li className="inline-flex items-center">
          <button
            onClick={() => navigateTo("/")}
            type="button"
            className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
          >
            <svg
              className="w-3 h-3 me-2.5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
            </svg>
            Home
          </button>
        </li>
        {pathnames.map((value, index) => {
          const last = index === pathnames.length - 1;
          const last_idx = pathnames.length - 1;
          const to = `/${pathnames.slice(0, index + 1).join("/")}`;
          return (
            <li key={to} className={last ? "text-gray-500" : ""}>
              <div className="flex items-center">
                <svg
                  className="rtl:rotate-180 block w-3 h-3 mx-1 text-gray-400 "
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 6 10"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 9 4-4-4-4"
                  />
                </svg>
                {index !== last_idx ? (
                  <button
                    type="button"
                    onClick={() => navigateTo(to)}
                    className="ms-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white"
                    aria-current={last_idx ? "page" : undefined}
                  >
                    {value.charAt(0).toUpperCase() + value.slice(1)}
                  </button>
                ) : (
                  <div className="ms-1 cursor-none text-sm font-medium text-gray-700 md:ms-2 ">
                    {value.charAt(0).toUpperCase() + value.slice(1)}
                  </div>
                )}
              </div>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default BreadCrumb;
