import { useEffect, useState } from "react";
import TemplatePage from "../template/template";
import { useNavigate, useParams } from "react-router-dom";
import { GlobalApi } from "../../global";
import Button from "../../components/button";
import { decode } from "../../cryptho/encryption";

const VaultView = () => {
  const { id, folder } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [data, setData] = useState({});
  const [decodeContent, setDecodeContent] = useState("");
  const [encode, setEncode] = useState(false);

  useEffect(() => {
    getEnv();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getEnv = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${GlobalApi()}/api/vault/env/${id}/${folder}`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      const data = await response.json();
      if (data.status === "error" && data.type === "jwt") {
        navigate("/login");
      }
      if (data.status === "error") {
        setError(data.error);
      }
      if (data.status === "ok" && data.env) {
        const dec_env = await decode(data.env);

        setData(dec_env);
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  const handleDecrypt = async (e) => {
    e.preventDefault();
    setLoading(true);
    setEncode(!encode);
    try {
      const response = await fetch(
        `${GlobalApi()}/api/vault/env/decode/${id}`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      const data = await response.json();
      if (data.status === "error" && data.type === "jwt") {
        navigate("/login");
      }
      if (data.status === "error") {
        setError(data.error);
      }
      if (data.status === "ok" && data.decode) {
        const dec_env = await decode(data.decode);
        setDecodeContent(dec_env.data);
        setError("");
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };
  return (
    <TemplatePage loading={loading} setLoading={setLoading}>
      <div className="home_selection_models w-full min-h-[calc(100vh-160px)] py-4 flex flex-col gap-4 overflow-auto scrollbar">
        <form onSubmit={handleDecrypt}>
          <h1 className="text-primary_token text-2xl py-4">Env:</h1>
          <h1 className="text-primary_token text-2xl py-4 underline-offset-2 underline ">
            {data?.FileName}
          </h1>
          {error && <p className="text-red-600">{error}</p>}
          <textarea
            autoCapitalize="none"
            autoCorrect="off"
            type="text"
            placeholder="env"
            id="env_file"
            name="env file"
            value={!encode ? data?.FileContent : decodeContent}
            rows={20}
            readOnly
            className="w-full bg-gray-[#121C22] text-gray-400 p-1 border rounded-md scrollbar border-primary bg-chat focus:ring focus:outline-none"
          />
          <Button>{encode ? "Encrypt" : "Decrypt"}</Button>
        </form>
      </div>
    </TemplatePage>
  );
};

export default VaultView;
